<template>
    <!-- 图片集 -->
    <div class="gallery_box">
        <div class="photo-gallery" id="scroll">
            <div style="min-height: 95%;">
                <van-loading :color="themecolors" :text-color="themecolors" v-if="Loading" />
                <van-empty :image="require('@/assets/null.svg')" v-else-if="imgList.length == 0" />
                <img v-else v-lazy="item.image_url" alt="" v-for="(item, index) in imgList" :key="index"
                    @click="imagePreview(item, index)">
            </div>
            <Support></Support>
        </div>
        <van-image-preview v-model="imgShow" :closeable="true" :showIndicators="false" :startPosition="imgIndex"
            :images="images" v-if="imgShow">
            <template v-slot:cover>
                <div class="arrow">
                    <div class="left" @click="arrowLeft(imgIndex)">
                        <van-icon name="arrow-left" />
                    </div>
                    <div class="left" @click="arrowRight(imgIndex)">
                        <van-icon name="arrow" />
                    </div>
                </div>
            </template>
        </van-image-preview>
    </div>

</template>

<script>
import { ImagePreview } from "vant"; // 引入Vant图片预览组件
import Support from '@/components/support';
import { mapState } from 'vuex'
export default {
    components: {
        Support
    },
    data() {
        return {
            params: {},
            imgList: [],
            Loading: false,
            show_grouping: '', // 云展厅传入分组id
            imgShow: false,
            imgIndex: '0',
            images: [],
        }
    },
    props: {
        app: Object,
    },
    created() {
        let obj = {
            module_id: this.app.random_number,
            page_id: this.app.page_id,
            site_id: this.site_id,
        }
        this.params = obj;
       
    },
    mounted() {
        if (!this.app.exhibition_skip) {
            this.get_image_list();
        } else {
            this.showroom_picture_list()
        }
    },
    computed: {
        ...mapState(['themecolors',"site_id"])
    },
    methods: {
        // 展厅图片分类列表
        showroom_picture_list() {
            this.Loading = true;
            this.images = []
            this.$store.dispatch("showroom_picture_list", {
                site_id: this.params.site_id,
                show_grouping: this.app.show_grouping
                // show_grouping:218
            }).then(res => {
                if (res.data.code === 200) {
                    this.Loading = false;
                    this.imgList = res.data.data.module_content
                    this.imgList.forEach(item => {
                        this.images.push(item.image_url)
                    })
                }
            })
        },
        get_image_list() {
            this.Loading = true;
            this.images = []
            this.$store.dispatch("get_image_list", {
                site_id: this.params.site_id,
                module_id: this.params.module_id,
            }).then(res => {
                this.Loading = false;
                if (res.data.code == 200) {
                    this.imgList = res.data.data.module_content;
                    this.imgList.forEach(item => {
                        this.images.push(item.image_url)
                    })
                }
            })
        },
        imagePreview(items, index) {
            this.imgShow = true
            this.images.forEach((item, index) => {
                if (item == items.image_url) {
                    this.imgIndex = index
                }
            })
        },
        carouselPreview() {
            let img = [];
            this.imgList.forEach(i => img.push(i.file_link_url))
            ImagePreview({
                images: img, //需要预览的图片 URL 数组
                showIndex: true, //是否显示页码
                loop: false, //是否开启循环播放
                closeable: true,  // 显示关闭按钮
                startPosition: 0, //图片预览起始位置索引
            });
        },
        arrowLeft(index) {
            let idx = index - 1
            if (idx == -1) {
                idx = this.images.length - 1
            }
            this.imgIndex = idx

        },
        arrowRight(index) {
            let idx = index + 1
            if (idx == this.images.length) {
                idx = 0
            }
            this.imgIndex = idx
        },
    }
}
</script>

<style lang="less" scoped>
/deep/.van-empty,
/deep/.van-loading {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
}

/deep/.van-empty {
    width: 100%;

    .van-empty__image {
        width: 300px;
        height: 300px;
    }
}

/deep/.van-loading {
    top: 45%;
}

.gallery_box {
    height: 100%;
    box-sizing: border-box;
    max-width: 420px;
    background: #fff;
}

.photo-gallery {
    height: 100%;
    overflow-y: scroll;

    img {
        display: block;
        width: 100%;
        height: auto;
    }
}

/deep/.van-image-preview__cover {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);

    .arrow {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0 10px;

        .left {
            font-size: 15px;
            background: #c8c9cc;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            padding: 4px;
            cursor: pointer;
        }
    }
}
</style>