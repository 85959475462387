<template>
<!-- 日程 -->
<div>
    <brandNewIndex :params="params" v-if="ScheduleType==1"/>
    <newindex :params="params" v-else-if="ScheduleType==2"/>
</div>
</template>
<script>
import { mapState } from 'vuex'
import brandNewIndex from './brandNewIndex.vue';
import newindex from './newindex.vue';
export default {
    components:{
        brandNewIndex,
        newindex
    },
    data(){
        return{  
           Loading:true,
           params:null,
           ScheduleType:"",
        }
    },
    props:{
        app:Object
    },
    computed: {
        ...mapState(['themecolors', 'show_schedule',"site_id"])
    },
    created(){
        let obj = {
            module_id: this.app.random_number,
            page_id: this.app.page_id,
            site_id: this.site_id,
        }
        this.params=obj
         this.get_schedule_library_info_list()
    },

    methods:{
        get_schedule_library_info_list(){
            this.$store.dispatch("get_schedule_library_info_list",{
                page_id:this.site_id,
            }).then(res=>{
                if(res.data.code == 200) {
                    if(res.data.data.list){
                         this.ScheduleType=res.data.data.mode
                    }else{
                         this.ScheduleType="1"
                    }
                   this.Loading=false
                }
            })
         }
    },
}
</script>
