<template>
    <!-- 日程 -->
    <div class="schedule" id="scroll" @scroll='showOut' ref="schendulScroll" :style="{'--themecolor':themecolors}"
        v-if="show_schedule">
        <div style="min-height:95%;position: relative;">
            <div class="schedule-top">
                <div class="top">
                    <van-search shape="round" v-model="value" placeholder="请输入讲者或主持姓名" @search="scheduleSearch" />
                </div>
                <div class="tabs" v-if="date_lineShow" :style="{ padding: date_line.length > 4 ? '0 30px' : '0 10px' }">
                    <span class="tabs_left" v-if="date_line.length > 4">
                        <van-icon name="arrow-left" :color="themecolors" />
                    </span>
                    <span class="tabs_arrow" v-if="date_line.length > 4">
                        <van-icon name="arrow" :color="themecolors" />
                    </span>
                    <div class="swiper mySwiper" ref="myswiper" :key="date_line.length">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide tab-box" v-for="(i, j) in date_line" :key="j"
                                :class="{ 'tab-active': i.id == schedule_library_id }" @click="active_meeting(i, j)">
                                <span class="week" v-if="i.type == 1">{{ i.week }}</span>
                                <span class="tab-title" :style="i.type == 1 ? 'margin-top:02px' : 'font-size:13px'">{{
                                i.title }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom" v-if="date_lineShow">
                <van-loading :color="themecolors" :text-color="themecolors" v-if="Loading" />
                <div class="null" v-else-if="schedule_place_config.length == 0">
                    <div class="null-title">
                        <p>暂无会场</p>
                    </div>
                    <van-empty :image="require('@/assets/attend/none-img.svg')" />
                </div>
                <template v-else>
                    <div class="bottom_main">
                        <div class="session">
                            <ul class="session-title">
                                <li v-for="(item, index) in schedule_place_config" :key="index">
                                    <div class="session_venue_title">{{ item.venue_title }}</div>
                                </li>
                            </ul>
                            <div :class="{ 'session-boxpc': ua != 1 }" class="session-box" ref="sessionScroll"
                                @scroll='showsession'>
                                <div class="session-main" v-for="(item, index) in schedule_place_config" :key="index">
                                    <div class="session-item" v-for="(i, j) in item.agendum_info" :key="j">
                                        <div class="pad-box" :class="{ 'live-conduct': i.live == true }">

                                            <div class="item-title" :style="{ background: themecolors }">
                                                <div class="title-left">
                                                    <span>{{ i.start_time_format }}</span>
                                                    <span class="interval">-</span>
                                                    <span>{{ i.end_time_format }}</span>
                                                </div>
                                            </div>
                                            <div class="item-main">
                                                <div class="meeting-title"
                                                    @click.stop="viewDetail(i, item.agendum_info, item)">
                                                    <p :class="{ liveshow: i.live && item.studio_info != '' }">
                                                        {{ i.agendum_title }}</p>
                                                </div>
                                                <div class="item_speaker" v-if="i.people_library[1] && !i.live">
                                                    <span> <strong>讲者：</strong> <span
                                                            v-for="(j, k) in i.people_library[1]" :key="k"
                                                            class="speaker_name">{{ j.name }}</span></span>
                                                </div>
                                                <div class="meeting-bottom" v-if="i.live && item.studio_info">
                                                    <div class="live-btn" @click="liveUrl(item)">
                                                        <img src="@/assets/schedule/live-conduct.gif" class="live-gif"
                                                            alt="">
                                                        <span>直播</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <div v-if="iscover" class="cover_img">
                    <div class="img_item">
                        <img :src="coverurl">
                    </div>
                    <div class="btns" @click="detailClick">进入详情</div>
                </div>
            </div>
            <div class="date_line_box" v-else>
                <van-loading :color="themecolors" :text-color="themecolors" v-if="Loading" />
                <div v-else>
                    <van-empty :image="require('@/assets/attend/none-img.svg')" />
                    <div class="date_line-title">
                        <p>暂无日程</p>
                    </div>
                </div>
            </div>
        </div>
        <Support></Support>
    </div>
    <Newdetail v-else></Newdetail>
</template>
<script>
import Swiper from "swiper";
import { mapState } from 'vuex'
import Support from '@/components/support';
import Newdetail from './newdetail.vue'
export default {
    components: {
        Support,
        Newdetail
    },
    props: {
        params: Object
    },
    data() {
        return {
            displayindex: 0,//显示第几个日程
            ua: 0,
            scrollTop: 0,
            scrollleft: 0,
            value: "",
            unfold: false,
            Loading: false,
            meeting_place_id: null,
            schedule_library_id: null,
            schedule_place_config: [],
            date_line: [],
            date_lineShow: false,
            oldid: null,
            iscover: false,
            coverurl: '',
            arrow_up: false,
            showAttention: false,
            venue: [],//所以的会场
        }
    },
    created() {
        this.get_schedule_library_info_list();
        if (sessionStorage.getItem('user_header') == 1 && localStorage.getItem('wxmessage')) {
            this.showAttention = true
        } else {
            this.showAttention = false
        }
    },
    computed: {
        ...mapState(['themecolors', 'show_schedule'])
    },
    watch: {
        show_schedule(val) {
            if (val) {
                this.$nextTick(() => {
                    this.$refs.schendulScroll.scrollTop = this.scrollTop
                    if (this.$refs.sessionScroll) {
                        this.$refs.sessionScroll.scrollLeft = this.scrollleft
                    }
                    new Swiper(".mySwiper", {
                        slidesPerView: "4",
                        spaceBetween: 12,
                        freeMode: true,
                        observer: true,
                        observeParents: true,
                    });
                })
            }
        },
        date_line(val) {
            if (val) {
                this.$nextTick(() => {
                    new Swiper(".mySwiper", {
                        slidesPerView: "4",
                        spaceBetween: 12,
                        freeMode: true,
                        observer: true,
                        observeParents: true,
                    });
                })
            }
        }
    },
    mounted() {
        if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
            this.ua = 1
        }
    },
    methods: {
        // 监听滚动的位置
        showOut(e) {
            this.scrollTop = e.target.scrollTop;

        },
        showsession(e) {
            this.scrollleft = e.target.scrollLeft;
        },
        active_meeting(val, index) {
            if (val.id != this.prohibit) {
                // this.meeting_place_id = val.meeting_place_id, 
                this.displayindex = index
                this.schedule_library_id = val.id
                this.schedule_place_config = this.date_line[index].scheduleList
                this.dragDistance = 0;
                this.prohibit = val.id
            }
        },
        //关注或取消关注日程
        async attentionSchedule(val, index, valIndex) {
            if (this.$cookies.isKey('microtoken')) {
                this.$toast('请用微信浏览器打开！')
                return
            }
            let res
            if (this.schedule_place_config[index].timeline[valIndex].follow_status == 0) {
                res = await this.$store.dispatch('add_follow_the_schedule', {
                    id: val.id,
                    site_id: this.$route.query.site_id,
                    page_id: this.$route.query.page_id,
                    module_id: this.$route.query.module_id
                })
            } else {
                res = await this.$store.dispatch('delete_follow_the_schedule', val.id)
            }
            if (res.data.code == 200) {
                if (this.schedule_place_config[index].timeline[valIndex].follow_status == 0) {
                    this.schedule_place_config[index].timeline[valIndex].follow_status = 3
                } else {
                    this.schedule_place_config[index].timeline[valIndex].follow_status = 0
                }
                this.$toast(res.data.message)
            } else {
                this.$toast(res.data.message)
            }
        },
        liveUrl(item) {
            if (!item.studio_info) {
                this.$toast({
                    message: '当前会议未添加直播间!',
                });
            } else {
                let obj = {
                    studio_id: item.studio_id,
                    site_id: this.params.site_id,
                    form_id: this.$cookies.get("form_user_id")
                }
                this.$store.dispatch("get_user_form_identity", obj).then(res => {
                    if (res.data.code == 200) {
                        location.href = item.studio_info.studio_url + '?liguserinfo=' + res.data.data.token;
                    }
                    if (res.data.code == 206) {
                        location.href = item.studio_info.studio_url;
                    }
                }).catch(err => {
                    location.href = item.studio_url;
                })
            }

        },
        /** 转日期格式 */
        getLocalTime(time) {
            var now = new Date(time);
            var year = now.getFullYear();
            var month = now.getMonth() + 1;
            var date = now.getDate();
            var hour = now.getHours();
            var minute = now.getMinutes();
            var second = now.getSeconds();
            return year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second;
        },
        // 转日期格式
        timestampToTime(time) {
            // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let date = new Date(time * 1000)
            let y = date.getFullYear()
            let MM = date.getMonth() + 1
            MM = MM < 10 ? ('0' + MM) : MM
            let d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            let h = date.getHours()
            h = h < 10 ? ('0' + h) : h
            let m = date.getMinutes()
            m = m < 10 ? ('0' + m) : m
            let s = date.getSeconds()
            s = s < 10 ? ('0' + s) : s
            return MM + '-' + d
        },
        timestampToweek(time) {
            // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
            let date = new Date(time * 1000)
            let y = date.getFullYear()
            let MM = date.getMonth() + 1
            MM = MM < 10 ? ('0' + MM) : MM
            let d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            let h = date.getHours()
            h = h < 10 ? ('0' + h) : h
            let m = date.getMinutes()
            m = m < 10 ? ('0' + m) : m
            let s = date.getSeconds()
            s = s < 10 ? ('0' + s) : s
            return y + '-' + MM + '-' + d
        },
        getWeek(dateString) {
            let dateArray = dateString.split("-");
            let date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2]);
            return "周" + "日一二三四五六".charAt(date.getDay());
        },
        /** 时间段范围 */
        isDuringDate(beginDateStr, endDateStr) {
            var curDate = new Date(),
                beginDate = new Date(beginDateStr),
                endDate = new Date(endDateStr);
            if (curDate >= beginDate && curDate <= endDate) {
                return true;
            }
            return false;
        },
        get_schedule_library_info_list() {
            this.Loading = true;
            this.$store.dispatch("get_schedule_library_info_list", {
                page_id: this.params.site_id,
            }).then(res => {
                if (res.data.code == 200 && res.data.data.length != 0) {
                    this.get_schedule_venue_info_all_list();
                    const todeday = new Date(new Date().toLocaleDateString()).getTime()
                    const endday = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1
                    this.displayindex = res.data.data.list.findIndex(item => todeday <= Number(item.title) * 1000 && Number(item.title) * 1000 <= endday)
                    if (this.displayindex == -1) {
                        this.displayindex = 0
                    }
                    res.data.data.list.forEach((item) => {
                        item.scheduleList = []
                        if (item.type == 1) {
                            let week = this.timestampToweek(item.title)
                            item.title = this.timestampToTime(item.title)
                            item['week'] = this.getWeek(week)
                        }
                    })
                    this.date_line = res.data.data.list;
                    if (this.date_line.length > 0) {
                        this.date_lineShow = true
                    }
                    if (this.date_line[this.displayindex].cover_url) {
                        this.iscover = true
                        this.coverurl = this.date_line[this.displayindex].cover_url
                    } else {
                        this.iscover = false
                    }
                    this.schedule_library_id = this.date_line[this.displayindex].id
                    this.schedule_place_config = this.date_line[this.displayindex].scheduleList
                    if (this.$refs.myswiper) {
                        this.$refs.myswiper.swiper.slideTo(this.displayindex, 0, true);
                    }

                } else {
                    this.Loading = false
                }
            })
        },
        // 获取议程信息
        get_schedule_agendum_info_all_list() {
            this.$store.dispatch("get_schedule_agendum_info_all_list", {
                page_id: this.params.site_id,
                // module_id:this.params.module_id,
            }).then(res => {
                if (res.data.code == 200) {
                    this.venue.forEach((item1) => {
                        res.data.data.forEach((item, index) => {

                            item.forEach((i, ind) => {
                                i.live = this.isDuringDate(i.start_time * 1000, i.end_time * 1000);
                                if (i.live == true) {
                                    if (item?.studio_info != "") {
                                        this.venue[index].agendum_info.unshift(i)
                                        this.venue[index].agendum_info.splice(ind + 1, 1)
                                    }
                                }
                            })
                            if (item[0]?.venue_id == item1.id) {
                                item1.agendum_info = item
                            }
                        })
                    })

                    this.date_line.forEach((item) => {
                        this.venue.forEach((item2) => {
                            if (item.id == item2.library_id) {
                                item.scheduleList.push(item2)
                            }
                        })
                    })
                    this.Loading = false;
                }
            })
        },
        // 获取所有场地
        get_schedule_venue_info_all_list() {
            this.$store.dispatch("get_schedule_venue_info_all_list", {
                page_id: this.params.site_id,
                // module_id:this.params.module_id,
            }).then(res => {
                if (res.data.code == 200) {
                    res.data.data.forEach((item) => {
                        item.agendum_info = []
                    })
                    this.venue = res.data.data
                    this.venue.forEach((item, index) => {
                        if (item.status == 2) {
                            this.venue.splice(index, 1)
                        }
                    })
                    this.get_schedule_agendum_info_all_list()
                }
            })
        },
        unfoldClick(item) {
            this.schedule_place_config.forEach(i => {
                i.id != item.id ? i.unfold = false : '';
            })
            item.unfold = !item.unfold;
        },
        scrollLeft() {
            this.scroll_left = $('.session-main').scrollLeft();
        },
        listenerFunction(e) {
            document.addEventListener('scroll', this.scrollLeft, true);
        },
        viewDetail(i, timeline, item) {
            window.sessionStorage.setItem("scheduleTimelineCurrentItem", JSON.stringify(item))
            let obj = {
                component: "ScheduleInfo",
                hide: false,
                height: 700,
                width: 420,
                key: 'demo_scheduleInfo',
                module_title: item.venue_title,
                reload: true,
                pid: new Date().valueOf() + "." + parseInt(Math.random() * 99999999),
                data: {
                    id: i.venue_id,
                    ...this.params,
                }
            }
            this.$store.commit("openApp", obj);
        },
        scheduleSearch(value) {
            this.$store.commit('changeschedulevalue', value)
            let objData = {
                component: "Attend",
                hide: false,
                height: 700,
                width: 420,
                key: 'demo_Attend',
                module_title: "嘉宾库",
                reload: true,
                disableResize: true,
                pid: new Date().valueOf() + "." + parseInt(Math.random() * 99999999),
                random_number: this.params.module_id,
                page_id: this.params.page_id,
            }
            this.$store.commit("openApp", objData);
        },
        detailClick() {
            this.iscover = false
        }
    },
    beforeDestroy() {
        document.removeEventListener("scroll", this.listenerFunction);
    },
}
</script>
<style lang='less' scoped>
@Color: var(--themecolor);

/deep/.van-empty,
/deep/.van-loading {
    position: absolute;
    top: 28%;
    left: 50%;
    transform: translateX(-50%);
}

/deep/.van-empty {
    width: 100%;

    .van-empty__image {
        width: 300px;
        height: 300px;
    }
}

/deep/.van-loading {
    top: 50%;
}

.schedule {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #f2f6f8;
    overflow: hidden;
    overflow-y: scroll;
    top: 0;
    bottom: 0;
    left: 0;

    .schedule-top {
        background: #fff;
    }

    .top {
        max-width: 420px;
        padding: 14px 16px;
        width: 100%;

        /deep/.van-search {
            padding: 0px;

            .van-search__content--round {
                border-radius: 30px;
                background-color: #F7F8FA;
            }

            .van-search__content {
                .van-cell {
                    color: #8F9DAF;

                    .van-icon {
                        font-weight: bold;
                    }
                }

                .van-field__body {
                    .van-field__control {
                        color: #8F9DAF;
                        padding-left: 10px;
                    }
                }
            }
        }
    }

    .tabs {
        position: relative;
        height: 60px;

        &::-webkit-scrollbar {
            height: 0px;
        }

        .van-icon {
            font-weight: bold;
        }

        .tabs_left {
            left: 2px;
            font-size: 18px;
            position: absolute;
            top: 10px;
            animation: tabsleft 1s linear infinite;
        }

        .tabs_arrow {
            right: 2px;
            font-size: 18px;
            position: absolute;
            top: 10px;
            animation: tabsright 1s linear infinite;
        }

        @keyframes tabsleft {
            0% {
                left: 2px;
            }

            50% {
                left: 8px;
            }

            100% {
                left: 2px;
            }
        }

        @keyframes tabsright {
            0% {
                right: 2px;
            }

            50% {
                right: 8px;
            }

            100% {
                right: 2px;
            }
        }

        .tab-box {
            width: 80px !important;
            height: 50px;
            border-radius: 10px;
            overflow: hidden;
            background: #F7F8FA;
            box-sizing: border-box;
            display: flex;
            padding: 6px 11px;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            align-items: center;

            .week {
                font-size: 12px;
                color: #8F9DAF;
            }

            .tab-title {
                font-size: 15px;
                color: #1F5129;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                // font-family: 'DinMedium';
                display: block;
                max-width: 100%;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .tab-active {
            background: @Color;

            .week {
                color: #fff;
            }

            .tab-title {
                color: #fff;
                // font-family: 'DinBold';
                font-weight: 600;
            }

        }
    }

    .bottom {
        padding-top: 16px;
        background: #f2f6f8;

        .cover_img {
            position: absolute;
            top: 0;
            width: 100%;
            height: fit-content;
            background: #fff;

            .img_item {

                // min-height: calc(100vh - 030px);
                // background-color: red;
                img {
                    width: 100%;
                }
            }

            .btns {
                position: fixed;
                bottom: 25px;
                left: 0;
                right: 0;
                margin: 0 auto;
                background-color: rgba(0, 225, 175, 0.6);
                border-radius: 35px;
                width: 180px;
                height: 35px;
                line-height: 35px;
                text-align: center;
                color: #fff;
                font-size: 13px;
            }
        }

        .null {
            width: 100%;
            height: auto;
            background: #fff;
            border-radius: 10px;
            margin-bottom: 16px;

            .null-title {
                padding: 0px 16px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px #F5F6FA solid;
                height: 44px;

                p {
                    font-size: 18px;
                    width: 78%;
                    // font-family: 'PingFangBold';
                    font-weight: bold;
                }
            }

            /deep/.van-empty {
                position: static;
                transform: none;

                .van-empty__image {
                    margin: 10px 0;
                }
            }
        }

        .bottom_main {
            .session {
                width: 100%;
                height: auto;
                border-radius: 10px;
                // margin-bottom: 16px;
                display: flex;

                .session-title {
                    width: 20%;
                    min-width: 100px;
                    max-width: 120px;
                    margin: 0 4px 0 4px;

                    // border-bottom: 1px #F5F6FA solid;
                    li {
                        border-radius: 7px;
                        word-break: break-word;
                        width: 100%;
                        background: #fff;
                        font-size: 14px;
                        padding: 0 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 120px;
                        margin-bottom: 10px;

                        .session_venue_title {
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 3;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            text-align: center;
                        }

                        &:last-child {
                            margin-bottom: 0px;
                        }
                    }

                    .title-right {
                        display: flex;
                        align-items: center;

                        span {
                            font-size: 12px;
                            margin-right: 8px;
                            color: #8F9DAF;
                        }
                    }
                }

                .session-box {
                    width: 100%;
                    overflow-x: scroll;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    .session-main {
                        display: flex;
                        margin-bottom: 10px;

                        &::after {
                            content: "";
                            display: block;
                            padding-right: 10px;
                            height: 120px
                        }

                        .session-item {
                            width: 120px;
                            height: 120px;
                            box-sizing: border-box;
                            margin-left: 10px;
                            flex-shrink: 0;

                            .pad-box {
                                user-select: none;
                                border-radius: 7px;
                                width: 100%;
                                height: 100%;
                                overflow: hidden;
                                border: 1px solid #E1E3EE;
                                // border-radius: 20px;
                                box-shadow: 0px 2px 6px #E8ECF1;
                                box-sizing: content-box;
                            }

                            .item-title {
                                width: 100%;
                                height: 33px;
                                display: flex;
                                font-size: 12px;
                                justify-content: center;
                                align-items: center;
                                padding: 0 10px;
                                background: #f5fffe;
                                color: #fff;
                                border-bottom: solid 1px #e1e2ec;

                                .title-left {
                                    display: flex;
                                    align-items: center;

                                    span {
                                        display: flex;
                                        font-size: 13px;
                                        // font-family: 'DinBold';
                                        font-weight: bold;
                                    }

                                    .interval {
                                        margin: 0px 2px;
                                    }

                                }
                            }

                            .item-main {
                                position: relative;
                                padding: 10px;

                                .meeting-title {
                                    height: 50px;

                                    p {
                                        font-size: 14px;
                                        color: #505D6F;
                                        // font-family: 'PingFangBold';
                                        display: -webkit-box;
                                        word-break: break-all;
                                        text-overflow: ellipsis;
                                        overflow: hidden;
                                        white-space: pre-line;
                                        -webkit-box-orient: vertical;
                                        -webkit-line-clamp: 2;
                                    }

                                    .liveshow {
                                        -webkit-line-clamp: 2;
                                    }
                                }

                                .meeting-bottom {
                                    position: absolute;
                                    bottom: -3px;
                                    right: 0;
                                    width: 100%;
                                    display: flex;
                                    justify-content: flex-end;

                                    .live-btn {
                                        background: #F5FFFE;
                                        //    border: 1px solid #D5D7E4;
                                        width: 100%;
                                        height: 30px;
                                        border-radius: 20px;
                                        max-width: 80px;
                                        margin: 0 auto;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;

                                        span {
                                            font-size: 14px;
                                            color: #8F9DAF;
                                            margin-left: 8px;
                                        }
                                    }
                                }

                                .item_speaker {
                                    font-size: 14px;
                                    width: 100%;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;

                                    .speaker_name {
                                        &::after {
                                            content: "，";
                                        }

                                        &:last-child {
                                            &::after {
                                                content: "";
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .live-conduct {
                            border: 1px solid #FF5C60 !important;

                            .item-title {
                                background: #fff3f3;
                                border-bottom: 01px solid #FF5C60;
                                // .title-left{
                                //     span{
                                //        color: #FF5C60; 
                                //     }
                                // }
                            }

                            .item-main {
                                .live-btn {
                                    background: #fff2f2 !important;

                                    // border: 1px solid #FF5C60;
                                    span {
                                        color: #FF5C60 !important
                                    }
                                }
                            }
                        }
                    }
                }

                .session-boxpc {
                    width: 100%;
                    overflow-x: scroll;

                    &::-webkit-scrollbar {
                        display: block !important;
                        height: 10px;
                        width: 100% !important;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: @Color;
                        border-radius: 5px;
                    }
                }

                .session-main-two {
                    padding: 25px 16px;

                    .session-item {
                        position: relative;

                        .item-title {
                            display: flex;
                            align-items: center;

                            .title-right {
                                margin-left: 5px;
                                display: flex;
                                align-items: center;
                                padding: 5px 14px;
                                background: #F0F2FA;
                                border-radius: 10px;

                                span {
                                    display: flex;
                                    font-size: 12px;
                                    // font-family: 'DinBold';
                                    font-weight: bold;
                                }
                            }
                        }

                        &::after {
                            content: '';
                            height: 86%;
                            width: 1px;
                            background: #D5D7E4;
                            position: absolute;
                            top: 24px;
                            left: 5px;
                        }
                    }

                    .live-conduct {
                        .item-title {
                            .title-right {
                                background: #fff3f1;

                                span {
                                    color: #FF5C60;
                                }
                            }
                        }

                        .item-main {
                            .item-main-box {
                                border: solid 1px #FF5C60;

                                .meeting-bottom {
                                    .live-btn {
                                        background: #fff3f3;

                                        // border: 0.5px solid #FF5C60;
                                        span {
                                            color: #FF5C60
                                        }
                                    }
                                }
                            }
                        }

                        &::after {
                            background: #ff7174;
                        }
                    }
                }
            }
        }
    }

    .date_line_box {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        background: #fff;
        border-radius: 10px;
        margin-bottom: 16px;

        .date_line-title {
            position: absolute;
            bottom: 10px;
            padding: 0px 16px;
            width: 100%;

            // border-bottom: 01px #F5F6FA solid;
            p {
                margin: 0 auto;
                text-align: center;
                font-size: 14px;
                width: 78%;
                color: #999;
                // font-family: 'PingFangBold';
            }
        }

        /deep/.van-empty {
            position: static;
            transform: none;
            padding: 0;

            .van-empty__image {
                margin: 10px 0;
            }
        }
    }
}

.live-gif {
    width: 24px;
    height: auto;
}

.swiper {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
</style>