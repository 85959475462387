<template>
    <!-- 报名缴费 -->
    <div class="RegPayment">
        <loading v-show="isHttpData" style="margin-top: 210px;"></loading>
        <div class="RegPayment-box" v-if="!isHttpData && payData.length != 0">
            <div class="pay-main">
                <div class="pay-title flex-between">
                    <div class="pay-name">缴费金额</div>
                    <div class="pay-number">￥{{payData.payment_amount?payData.payment_amount:0 }}</div>
                </div>
                <div class="pay-introduce">
                    <div class="introduce-content flex-between">
                        <div class="content-left">
                            <p v-html="payData.describe"></p>
                        </div>
                        <div class="content-right">
                            <img src="@/assets/pay/anquan.svg" alt="">
                            <i class="el-icon-arrow-right" v-if="showArrow" @click="unfold"></i>
                        </div>
                    </div>
                    <div class="pay-mode">
                        <van-collapse v-model="activeName" accordion>
                            <van-collapse-item name="1" :is-link="false">
                                <template #title>
                                    <div class="bankcard flex-start">
                                        <img src="@/assets/pay/yinhang.svg" alt="">
                                        <div class="flex-between">
                                            <span class="margin-left">银行卡</span>
                                            <van-radio-group v-model="activeName">
                                                <van-radio name="1" checked-color="#00ccb0"></van-radio>
                                            </van-radio-group>
                                            <div class="position-box"></div>
                                        </div>
                                    </div>
                                </template>
                                <div class="bankcard-content">
                                    <div class="bankcard-box">
                                        <div class="flex-start">
                                            <span class="bank_number">{{ payData.bank_card.bank_card_number}}</span>
                                            <span class="duplication duplication-number"
                                                :data-clipboard-text="payData.bank_card.bank_card_number"
                                                @click="copyNumber">复制</span>
                                        </div>
                                        <div class="flex-start">
                                            <span>收款人姓名：</span>
                                            <span class="user_name">{{ payData.bank_card.name }}</span>
                                            <span class="duplication duplication-name"
                                                :data-clipboard-text="payData.bank_card.name"
                                                @click="copyName">复制</span>
                                        </div>
                                        <div>开户行：{{ payData.bank_card.account_bank }}</div>
                                    </div>
                                    <p style="font-size: 13px; margin-top: 12px;">需要支付金额 <span
                                            style="color: #FB5C56;">￥{{payData.payment_amount}}</span></p>
                                    <p>转账时注意卡号和收款人姓名</p>
                                    <p> 完成付款后请点击下方“我已完成支付” <span style="color: #FB5C56;"> 上传支付凭证</span></p>
                                </div>
                            </van-collapse-item>
                            <van-collapse-item name="2" :is-link="false">
                                <template #title>
                                    <div class="alipay flex-start">
                                        <img src="@/assets/pay/zhifubao.svg" alt="">
                                        <div class="flex-between">
                                            <span class="margin-left">支付宝</span>
                                            <van-radio-group v-model="activeName">
                                                <van-radio name="2" checked-color="#00ccb0"></van-radio>
                                            </van-radio-group>
                                            <div class="position-box"></div>
                                        </div>
                                    </div>
                                </template>
                                <div class="bankcard-content">
                                    <div class="zfb-img">
                                        <img class="zfb-img-one" v-lazy="payData.alipay_url"
                                            @click="imagePreview(payData.alipay_url)" :src="payData.alipay_url" alt="">
                                        <img class="download" @click="saveImg(true)" src="@/assets/pay/download.svg"
                                            alt="">
                                    </div>
                                    <p style="font-size: 13px; margin-top: 12px;">需要支付金额 <span
                                            style="color: #FB5C56;">￥{{payData.payment_amount}}</span></p>
                                    <p>转账时注意卡号和收款人姓名</p>
                                    <p> 完成付款后请点击下方“我已完成支付” <span style="color: #FB5C56;"> 上传支付凭证</span></p>
                                </div>
                            </van-collapse-item>
                            <van-collapse-item name="3" :is-link="false">
                                <template #title>
                                    <div class="WeChat flex-start">
                                        <img src="@/assets/pay/weixin.svg" alt="">
                                        <div class="flex-between">
                                            <span class="margin-left">微信</span>
                                            <van-radio-group v-model="activeName">
                                                <van-radio name="3" checked-color="#00ccb0"></van-radio>
                                            </van-radio-group>
                                            <div class="position-box"></div>
                                        </div>
                                    </div>
                                </template>
                                <div class="bankcard-content">
                                    <div class="zfb-img">
                                        <img id="img-down" v-lazy="payData.wechat_url"
                                            @click="imagePreview(payData.wechat_url)" :src="payData.wechat_url" alt="">
                                        <img class="download" @click="saveImg(false)" src="@/assets/pay/download.svg"
                                            alt="">
                                    </div>
                                    <p style="font-size: 13px; margin-top: 12px;">需要支付金额 <span
                                            style="color: #FB5C56;">￥{{payData.payment_amount}}</span></p>
                                    <p>转账时注意卡号和收款人姓名</p>
                                    <p> 完成付款后请点击下方“我已完成支付” <span style="color: #FB5C56;"> 上传支付凭证</span></p>
                                </div>
                            </van-collapse-item>
                        </van-collapse>
                    </div>
                </div>
            </div>
            <div class="have-paid">
                <div class="have-paid-box flex-between" @click="completePay = !completePay">
                    <span>我已完成支付</span>
                    <van-checkbox v-model="completePay" checked-color="#00ccb0"></van-checkbox>
                </div>
                <!-- <Support style="margin-top: 20px;"></Support> -->
            </div>
            <div class="have-paid-btn">
                <van-button :disabled="!completePay" :color="!completePay?'#ccc':''" @click.stop="goPayPath">我已完成支付
                </van-button>
            </div>
        </div>
        <Support v-show="!isHttpData && payData.length == 0"
            style="position:absolute;bottom: 10px;left: 50%; transform: translate(-50%, 0);"></Support>
        <img v-show="!isHttpData && payData.length == 0" class="position-img" src="@/assets/null.svg" alt="">
    </div>
</template>

<script>
import Clipboard from "clipboard";
import Support from '@/components/support';
import Loading from "@/components/loading/loading";
import { ImagePreview } from "vant"; // 引入Vant图片预览组件
import { mapState } from 'vuex'
export default {
    data() {
        return {
            activeName: '1',
            orderInfo: '',
            name: '',
            completePay: false,
            isHttpData: true,
            payData: {
                bank_card: {
                    bank_card_number: ''
                }
            },
            dataURL: require('../../../assets/pay/zfb.png'),
            showArrow: false
        }
    },
    computed: {
        ...mapState(["site_id"])
    },
    props:{
        app:Object
    },
    components: {
        Support,
        Loading
    },
    async created() {
        let obj = {
            site_id: this.site_id,
            module_id: this.app.random_number
        }
        const res = await this.$store.dispatch('show_payment_page', obj)
        if (res.data.code == 200) {
            this.payData = res.data.data.module_content;
            this.isHttpData = false
            if (this.payData.length != 0) {
                this.$nextTick().then(() => {
                    var minHeight = document.querySelector('.content-left p').clientHeight
                    if (minHeight > 80) {
                        this.showArrow = true
                    } else {
                        this.showArrow = false
                    }
                })
            }
        }
    },
    methods: {
        imagePreview(url) {
            let img = [];
            img.push(url)
            ImagePreview({
                images: img, //需要预览的图片 URL 数组
                showIndex: true, //是否显示页码
                loop: false, //是否开启循环播放
                closeable: true,  // 显示关闭按钮
            });
        },
        unfold() {
            this.$nextTick().then(() => {
                var minHeight = document.querySelector('.content-left').clientHeight
                var pHeight = document.querySelector('.content-left p').clientHeight
                var arrow = document.querySelector('.content-right .el-icon-arrow-right')
                if (pHeight > 80) {
                    if (minHeight > 80) {
                        document.querySelector('.content-left').style.height = '80px'
                        arrow.classList.remove('is-rotate')
                    } else {
                        document.querySelector('.content-left').style.height = pHeight + 10 + 'px'
                        arrow.classList.add('is-rotate')
                    }
                }
            })
        },
        //下载图片
        saveImg(val) {
            if (val) {
                if (!this.payData.alipay_url) {
                    this.$toast('下载失败')
                } else {
                    var canvas = document.createElement('canvas');
                    var img = document.createElement('img');
                    img.onload = function (e) {
                        canvas.width = img.width;
                        canvas.height = img.height;
                        var context = canvas.getContext('2d');
                        context.drawImage(img, 0, 0, img.width, img.height);
                        canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height);
                        canvas.toBlob((blob) => {
                            let link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.download = 'Alipay';
                            link.click();
                        }, "image/jpeg");
                    }
                    img.setAttribute("crossOrigin", 'Anonymous');
                    img.src = this.payData.alipay_url;
                }
            } else {
                if (!this.payData.wechat_url) {
                    this.$toast('下载失败')
                } else {
                    var canvas = document.createElement('canvas');
                    var img = document.createElement('img');
                    img.onload = function (e) {
                        canvas.width = img.width;
                        canvas.height = img.height;
                        var context = canvas.getContext('2d');
                        context.drawImage(img, 0, 0, img.width, img.height);
                        // window.navigator.msSaveBlob(canvas.msToBlob(),'image.jpg');
                        // saveAs(imageDataUrl, '附件');
                        canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height);
                        canvas.toBlob((blob) => {
                            let link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.download = 'WeChat';
                            link.click();
                        }, "image/jpeg");
                        // context.toBlob(function(blob) {
                        // console.log('blob :', blob);
                        // let link = document.createElement('a');
                        // link.href = window.URL.createObjectURL(blob);
                        // link.download = 'aaa';
                        // }, "image/jpeg");
                    }
                    img.setAttribute("crossOrigin", 'Anonymous');
                    img.src = this.payData.wechat_url;
                }
            }
        },
        goPayPath() {
            let obj = {
                component: "Invoice",
                hide: false,
                height: 700,
                width: 420,
                key: 'demo_Invoice',
                module_title: '缴费信息',
                reload:true,
                pid: new Date().valueOf() + "." + parseInt(Math.random() * 99999999),
                site_id:this.site_id,
                random_number:this.app.random_number,
                Reqkey:this.app.key
            }
            this.$store.commit("openApp", obj);
        },
        async copyNumber() {
            var clipboard = new Clipboard(".duplication-number");
            clipboard.on("success", e => {
                // 释放内存
                this.$toast('复制成功');
                clipboard.destroy();
            });
            clipboard.on("error", e => {
                // 不支持复制
                this.$toast('复制失败');
                // 释放内存
                clipboard.destroy();
            });
        },
        async copyName() {
            var clipboard = new Clipboard(".duplication-name");
            clipboard.on("success", e => {
                // 释放内存
                this.$toast('复制成功');
                clipboard.destroy();
            });
            clipboard.on("error", e => {
                // 不支持复制
                this.$toast('复制失败');
                // 释放内存
                clipboard.destroy();
            });
        },
    }
}
</script>

<style lang="less" scoped>
@themecolor: #00ccb0;

.is-rotate {
    -ms-transform: rotate(90deg);
    /* IE 9 */
    transform: rotate(90deg);
}

.tranrotate {
    transform: rotate(45deg);
}

* {
    box-sizing: border-box;
}

.flex-start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.RegPayment {
    // height: calc(100vh-.44rem);
    overflow-y: visible;
    background-color: #F3F2F7;
    max-width:420px ;
    // padding: 12px 15px;
    // box-sizing: border-box;
    .RegPayment-box {
        height: 92%;
        overflow-y: scroll;
        padding: 12px 15px 30px;
    }

    .pay-main {
        background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
        width: 100%;

        .pay-title {
            height: 54px;
            font-size: 16px;
            padding: 0 10px;
        }

        .pay-number {
            color: #FB5A54;
        }

        .pay-introduce {
            padding: 12px 10px;
            box-sizing: border-box;
            background-image: linear-gradient(to right, rgba(0, 245, 211, 0.15), rgba(112, 214, 254, 0.15));

            .introduce-content {
                align-items: flex-start;

                .content-left {
                    flex: 1;
                    padding: 4px 6px;
                    box-sizing: border-box;
                    min-height: 60px;
                    height: 80px;
                    overflow: hidden;
                    transition: height 1s;
                    -moz-transition: height 1s;
                    -webkit-transition: height 1s;
                    -o-transition: height 1s;

                    p {
                        font-size: 13px;
                        line-height: 24px;
                    }
                }

                .content-right {
                    width: 45px;
                    height: 70px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;

                    img {
                        display: block;
                        width: 34px;
                        height: 38px;
                    }

                    .el-icon-arrow-right {
                        font-size: 24px;
                        margin-top: 4px;
                        z-index: 20;
                        transform: 1s;
                    }
                }
            }

            .pay-mode {
                width: 100%;
                border-radius: 10px;
                background-color: #fff;
                overflow: hidden;
                font-size: 14px;

                .bankcard {
                    img {
                        display: block;
                        width: 30px;
                        height: 20px;
                    }

                    div {
                        width: 100%;
                        // position: relative;
                    }
                }

                .alipay {
                    img {
                        margin-left: 2px;
                        display: block;
                        width: 24px;
                        height: 24px;
                    }

                    div {
                        width: 100%;
                        // position: relative;
                    }
                }

                .WeChat {
                    img {
                        margin-left: .3px;
                        display: block;
                        width: 25px;
                    }

                    div {
                        width: 100%;
                        // position: relative;

                    }
                }

                .bankcard-content {
                    background-color: #F7F7F7;
                    font-size: 12px;
                    padding: 15px 16px;
                    box-sizing: border-box;
                    border-radius: 10px;

                    .zfb-img {
                        position: relative;

                        img {
                            display: block;
                            width:100px;
                            height: 152px;
                            margin: 0 auto;
                            object-fit: scale-down;
                        }

                        .download {
                            width: 22px;
                            height: 22px;
                            display: block;
                            position: absolute;
                            right: 0;
                            top: 0;
                        }
                    }

                    .bankcard-box {
                        background: linear-gradient(to right, rgba(248, 127, 106, 0.8), rgba(251, 88, 83, 0.8));
                        width: 100%;
                        border-radius: 5px;
                        padding: 10px 0 18px 10px;
                        color: #fff;
                        box-sizing: border-box;

                        .bank_number {
                            font-size: 14px;
                        }

                        div {
                            height: 18px;
                        }

                        div:nth-child(2) {
                            margin: 3px 0 16px;
                        }

                        span {
                            height: 18px;
                            line-height: 18px;
                        }

                        .duplication {
                            margin-left: 18px;
                            display: block;
                            width: 37px;
                            height: 18px;
                            text-align: center;
                            color: #FB5A54;
                            background-color: #fff;
                            border-radius: 9px;
                        }
                    }

                    p {
                        color: #666;
                        text-align: center;
                        font-size: 10px;
                        line-height: 24px;

                    }
                }

                /deep/ .van-radio-group {
                    width: 20px !important;
                    height: 20px !important;

                    /deep/ i.van-icon.van-icon-success {
                        background-color: @themecolor !important;
                        border-color: @themecolor !important;
                    }

                    /deep/ .van-radio__icon--checked {
                        /deep/ i.van-icon {
                            background-color: #00ccb0 !important;
                            border-color: @themecolor !important;
                        }
                    }
                }

                .margin-left {
                    margin-left: 10px;
                    box-sizing: border-box;
                }
            }
        }
    }

    .have-paid {
        height: 54px;
        font-size: 16px;
        margin-top: 20px;

        .have-paid-box {
            padding: 0 25px;
            width: 100%;
            height: 54px;
            border-radius: 10px;
            background-color: #fff;
        }
    }

    .have-paid-btn {
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 65px !important;
        width: 100%;
        background-color: #fff;
        max-width: 420px;
        .van-button {
            position: fixed;
            left: 15px;
            bottom: 5px;
            border: .10px solid #00ccb0;
            color: #fff;
            background-color: #00ccb0;
            height: 54px;
            width: calc(100% - 30px);
            border-radius: 27px;
            // box-sizing: border-box;
        }
    }
}

.position-box {
    display: block;
    position: absolute;
    width: 60px;
    height: 40px !important;
    right: 0;
    top: 0;
    z-index: 99;
}

.position-img {
    position: fixed;
    width: 300px;
    height:300px;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
}
</style> 