<template>
  <div v-if="loading">
      <van-loading color="#00ccb0" :size='40' />
  </div>
  <div class="login_warp" v-else-if="loginShow&&!loading">
    <div class="login_info">
        <img src="@/assets/pcimage/warning.svg" alt="">
        <div class="login_text">确认登录轻微站?</div>
        <div class="login_title">确认后电脑端将完成登录</div>
        <van-button type="info" size="small" @click="official_account_pc_login" color="#00ccb0">确认登录</van-button>
    </div>
    <div class="login_icon">
        <img src="@/assets/pcimage/qwzlogo.svg" alt="">
    </div>
  </div>
  <div class="login_warp" v-else-if="!loginShow&&!loading">
    <div class="login_info">
        <img src="@/assets/pcimage/success.svg" alt="">
        <div class="login_text">授权成功</div>
        <div class="login_title">请继续在网页上操作</div>
    </div>
    <div class="login_icon">
        <img src="@/assets/pcimage/qwzlogo.svg" alt="">
    </div>
    
  </div>
</template>

<script>
export default {
    data(){
        return{
            loginShow:true,
            key:"",
            code:"",
            loading:false,
        }
    },
    created(){
        const url = new URL(window.location.href);
        this.key=url.searchParams.get("key")
        this.code=url.searchParams.get("code")
        document.title="登录-轻微站"
        if(this.code){
            this.loading=true
            this.get_pc_login_token()
            if(this.loginShow){
                this.official_account_call_back()   
            }
        }
        document.addEventListener('WeixinJSBridgeReady',function onBridgeReady() {
            WeixinJSBridge.call('hideOptionMenu');
        }); 
    },
    methods:{
         // PC端获取微信公众号登录授权
        official_account_pc_login(){
            this.$store
            .dispatch("official_account_pc_login",{key:this.key}).then(res=>{
                if(res.data.code==303){
                   window.location.href= res.data.redirect_url
                }
            })
        },
        //C端微信授权token
        get_pc_login_token(){
            this.$store
            .dispatch("get_pc_login_token",{key:this.key}).then(res=>{
                if(res.data.code==200){
                    this.loading=false
                    this.loginShow=false
                    document.title="扫码登录-轻微站"
                }
            })
        },
        // 微信登录信息
        official_account_call_back(){
            this.$store.dispatch("official_account_call_back",{
                code: this.code,
                key:this.key
            }).then(async res=>{
                if(res.data.code==200)[
                     this.get_pc_login_token()
                ]
            })
        }
    }
}
</script>
<style lang="less" scoped>
.login_warp{
    padding: 8vh 0 5vh;
    height: 100vh;
    text-align: center;
    font-size: .14rem;
    border-radius:0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .login_icon{
        margin: 0 auto;
        width: .8rem;
    }
    .login_info{
        img{
            width: .8rem;
            margin-bottom: .12rem;
        }
    }
}
.login_text{
    margin-bottom: .1rem;
}
.login_title{
    color: #999;
    font-size: .12rem;
    margin-bottom: .15rem;
}
.van-button--small{
    padding: 0 .1rem;
    font-size: .14rem;
}
.van-loading{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
} 
</style>