import Player from 'xgplayer'
// import RequestFullIcon from '../assets/requestFull.svg'
// import RequestFullIcon from '../assets/fullscreen.svg'
// import ExitFullIcon from '../assets/exitFull.svg'
// import ExitFullIcon from '../assets/fullscreen.svg'
import "jquery"
import '../style/controls/fullscreen.scss'

let s_fullscreen = function () {
  let player = this
  let util = Player.util
  let fullscreenBtn = player.config.fullscreenBtn ? player.config.fullscreenBtn : {}
  let btn
  if (fullscreenBtn.type === 'img') {
    btn = util.createImgBtn('fullscreen', fullscreenBtn.url.request, fullscreenBtn.width, fullscreenBtn.height)
  } else {
    btn = util.createDom('xg-fullscreen', `<xg-icon class="xgplayer-icon">
                                             <div  class="xgplayer-icon-requestfull">
                                             <svg class="icon iconplay" aria-hidden="true">
                                              <use  xlink:href="#icon-xgfullscreen"></use>
                                          </svg>
                                             </div>
                                             <div class="xgplayer-icon-exitfull">
                                             <svg class="icon iconplay" aria-hidden="true">
                                              <use  xlink:href="#icon-xgsuoxiao"></use>
                                          </svg>
                                             </div>
                                           </xg-icon>`, {}, 'xgplayer-fullscreen')
  }
  let tipsText = {}
  tipsText.requestfull = player.lang.FULLSCREEN_TIPS
  tipsText.exitfull = player.lang.EXITFULLSCREEN_TIPS
  let tips = util.createDom('xg-tips', `<span class="xgplayer-tip-requestfull">${tipsText.requestfull}</span>
                                        <span class="xgplayer-tip-exitfull">${tipsText.exitfull}</span>`, {}, 'xgplayer-tips')
  btn.appendChild(tips)
  let danmu
  danmu = util.createDom('xg-danmu', `<xg-icon class="xgplayer-icon">
                                             <div  id="xgplayer-icon-danmu">
                                              <svg class="icon" aria-hidden="true">
                                               <use xlink:href="#icon-barrage_img"></use>
                                              </svg>
                                              <svg class="icon iconstop  danmu-stop" aria-hidden="true">
                                                <use xlink:href="#icon-closebarrage"></use>
                                              </svg>
                                             </div>
                                             <div id="xgplayer-icon-danmuClose">
                                              <svg class="icon danmu-stop" aria-hidden="true">
                                                <use xlink:href="#icon-closebarrage"></use>
                                              </svg>
                                             </div>
                                           </xg-icon>`, {}, 'xgplayer-fullscreen')
  // let livestatus
  // livestatus = util.createDom('xg-livestatus', `<xg-icon class="xgplayer-icon">
  // <div id="xgplayer-icon-livestatus">
  // <img class="lig-live-img" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNC4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0i5Zu+5bGCXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOTYgMzYuOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgOTYgMzYuODsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0ZGMzMzMzt9DQoJLnN0MXtmb250LWZhbWlseTonUGluZ0ZhbmctU0MtUmVndWxhcic7fQ0KCS5zdDJ7Zm9udC1zaXplOjMycHg7fQ0KPC9zdHlsZT4NCjxnIGlkPSLlm77lsYJfMl8xXyI+DQoJPGcgaWQ9IuWbvuWxgl8xLTIiPg0KCQk8dGV4dCB0cmFuc2Zvcm09Im1hdHJpeCgxIDAgMCAxIDAgMjcuNTIpIiBjbGFzcz0ic3QwIHN0MSBzdDIiPuebtOaSreS4rTwvdGV4dD4NCgk8L2c+DQo8L2c+DQo8L3N2Zz4NCg=="/>
  // </div>
  // </xg-icon>`, {}, 'xgplayer-fullscreen')
  player.once('ready', () => {
    if(player.controls) {
      player.controls.appendChild(danmu)
      // player.controls.appendChild(livestatus)
      player.controls.appendChild(btn)
    }
  });

  ['click', 'touchend'].forEach(item => {
    btn.addEventListener(item, function (e) {
      e.preventDefault()
      e.stopPropagation()
      player.emit('fullscreenBtnClick')
    })
  });
  // let danmuOf=danmu.firstElementChild.firstElementChild
  // // danmuOf.addEventListener('click',function(){
  // //   if(danmuOf.firstElementChild.getAttribute('class')=='icon'){
  //     danmuOf.firstElementChild.classList.re('danmu-stop')
  //     danmuOf.lastElementChild.classList.remove('danmu-stop')
  //   }else{
  //     danmuOf.firstElementChild.classList.remove('danmu-stop')
  //     danmuOf.lastElementChild.classList.add('danmu-stop')
  //   }
  // })
}
export default {
  name: 's_fullscreen',
  method: s_fullscreen
}