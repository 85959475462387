<template>
<div class="qr_warp">
   <div class="qr_box">
    <div class="qrCode_title">扫码登录</div>
     <vue-qr
        ref="qrCode"
        :text="qrurl"
        colorDark="#333"
        colorLight="#fff"
        logoBackgroundColor="#fff"
        :margin="0"
        :logoScale="1"
        :size="250"
      >
      </vue-qr>
      <div class="qrCode_tips">请使用微信扫码登录</div>
      <div class="qrCode_map" v-if="empowerStatus" @click="empowerChange">
        <div><i class="el-icon-refresh-right"></i></div>
        <div class="qrCode_icon_title">二维码失效</div>
        <div class="qrCode_icon_title">请点击刷新</div>
      </div>
  </div>
</div>
 
</template>

<script>
export default {
  props: {
    qrurl: String,
    empowerStatus:Boolean,
  },
    methods:{
        empowerChange(){
           this.$emit("refreshQrcode")
        }
    }
}
</script>

<style lang="scss" scoped>
.qr_warp{
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: rgba(250,250,250,.8);
  backdrop-filter: blur(100px);
}
.qr_box{
  text-align: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
  img{
    display: flex !important;
  }
}   
.qrCode_map{
    width: 250px;
    height: 250px;
    position: absolute;
    top: 51px;
    left: 0;
    background: rgba($color: #fff, $alpha: .9);
    color: #00ccb0;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    box-sizing: border-box;
    .el-icon-refresh-right{
        // font-weight: bold;s
        margin-bottom: 10px;
        font-size: 30px;  
    }
    .qrCode_icon_title{
        font-weight: bold;
        margin-bottom: 5px;
    }
}
.qrCode_title{
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 20px;
  }
  .qrCode_tips{
    font-size: 14px;
    color: #424242;
    margin-top: 10px;
  }
</style>