<template>
    <!-- 新闻资讯-->
    <div class="articleListBox" id="scroll">
        <div class="topcon">
            <img :src="kvimg" v-if="kvimg">
        </div>
        <div class="topTitle">
            <div class="titlecon">
                <img src="@/assets/otherTU/articleicon.png">
                <span>文章列表</span>
            </div>
        </div>
        <div class="articleCenter">
            <van-loading :color="themecolors" :text-color="themecolors" v-if="Loading" />
            <van-empty :image="require('@/assets/null.svg')" v-else-if="listData.length == 0" />
            <van-list v-model="celloading" :finished="finished" finished-text="没有更多了" @load="onLoad"
                v-if="listData.length > 0">
                <van-cell v-for="(item, index) in listData" :key="index" class="conBox" @click.stop="articleDetails(item)">
                    <div class="center">
                        <img :src="item.thumbnail_url" alt="">
                        <div class="conright">
                            <p>{{ item.new_title }}</p>
                            <span>{{ item.abstract }}</span>
                            <div class="centertime flex"><span>{{ item.updated_at }}</span>
                                <div class="flex">
                                    <van-icon name="eye-o" style="font-size:15px;" />&nbsp;{{ item.views_count }}
                                </div>
                            </div>
                        </div>
                    </div>
                </van-cell>
            </van-list>
        </div>
        <Support></Support>
    </div>
</template>
 
<script>
import Support from '@/components/support';
import { mapState } from 'vuex'
export default {
    components: {
        Support,
    },
    data() {
        return {
            bannerHeight: null,
            listData: [],
            params: {},
            kvimg: '',
            Loading: true,
            isgoBack: false,
            page: 1,
            page_num: 20,
            celloading: false,
            finished: false,//下拉加载是否是最后
        }
    },
    props: {
        app:Object,
    },
    created() {
        let obj = {
            module_id: this.app.random_number,
            page_id: this.app.page_id,
            site_id: this.site_id,
        }
        this.params = obj
    },
    mounted() {
        let width = document.body.clientWidth;
        this.bannerHeight = (width / 16 * 9) / 100;
        if (!this.app.exhibition_skip) {
            this.news_information_list()
        } else {
            this.showroom_news_list()
        }
    },
    computed: {
        ...mapState(['themecolor', 'themecolors',"site_id"])
    },
    methods: {
        articleDetails(item) {
            let obj = {
                component: "ArticleDetails",
                hide: false,
                height: 700,
                width: 420,
                key: 'demo_news',
                module_title: item.new_title,
                reload:true,
                pid: new Date().valueOf() + "." + parseInt(Math.random() * 99999999),
                data: {
                    id: item.id,
                    ...this.params,
                }
            }
            // 云展示新闻详情跳转标识
            if (this.app.exhibition_skip) {
                obj.exhibition_skip=true
                obj.show_grouping=this.app.show_grouping
            }
            this.$store.commit("openApp", obj);
        },
        // 下拉加载数据
        onLoad() {
            this.page++
            if (!this.app.exhibition_skip) {
                this.news_information_list()
            } else {
                this.Loading = false
                this.finished = true;
            }
        },
        // 展厅展示新闻资讯列表
        showroom_news_list() {
            this.$store.dispatch("showroom_news_list", {
                site_id: this.site_id,
                show_grouping: this.app.show_grouping
            }).then(res => {
                if (res.data.code === 200) {
                    this.kvimg = res.data.data.list_kv
                    this.listData = res.data.data.data.module_content
                }
            })
        },
        news_information_list() {
            this.$store.dispatch("news_information_list", {
                site_id: this.site_id,
                module_id: this.params.module_id,
                page: this.page,
                page_num: this.page_num,
            }).then(res => {
                if (res.data.code == 200) {
                    this.listData = [...this.listData, ...res.data.data.module_content.data]
                    this.kvimg = res.data.data.list_kv
                    this.celloading = false;
                    if (res.data.data.module_content.current_page >= res.data.data.module_content.last_page) {
                        this.finished = true;
                    }
                    this.Loading = false
                }
            })
        },
        timestampToTime(time) {
            var date = new Date(time * 1000)
            let y = date.getFullYear()
            let MM = date.getMonth() + 1
            MM = MM < 10 ? ('0' + MM) : MM
            let d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            let h = date.getHours()
            h = h < 10 ? ('0' + h) : h
            let m = date.getMinutes()
            m = m < 10 ? ('0' + m) : m
            let s = date.getSeconds()
            s = s < 10 ? ('0' + s) : s
            return y + '-' + MM + '-' + d + ' ' + h + ":" + m + ":" + s
        }
    },
}
</script>

<style scoped lang="less">
/deep/.van-empty,
/deep/.van-loading {
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translateX(-50%);
}

/deep/.van-empty {
    width: 100%;

    .van-empty__image {
        width: 300px;
        height: 300px;
    }
}

/deep/.van-loading {
    top: 65%;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.articleListBox::-webkit-scrollbar {
    display: none;
}

.articleListBox {
    max-width: 420px;
    width: 100%;
    height: 100%;
    overflow: scroll;
    background: #fff;

    .topcon {
        width: 100%;
        height: 210px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .topTitle {
        width: 100%;
        height: 44px;
        background: #FFFFFF;
        box-shadow: 0 3px 8px #EEEEEE;

        .titlecon {
            width: 90%;
            height: 100%;
            margin: 0 auto;
            display: flex;
            align-items: center;

            img {
                width: 27px;
            }

            span {
                font-size: 14px;
                display: block;
                font-weight: bold;
                line-height: 44px;
                margin-left: 10px;

            }
        }
    }

    .articleCenter {
        width: 100%;
        // box-shadow: 0px 3px 8px #EEEEEE;
        min-height: 55%;
        margin-top: 10px;

        .van-list {
            /deep/.van-list__loading {
                .van-loading {
                    position: inherit;
                    transform: translateX(0);
                }
            }

        }

        .conBox {
            display: flex;
            width: 94%;
            background: #ffffff;
            margin: 0 auto;

            .center {
                width: 100%;
                height: 80px;
                display: flex;
                justify-content: space-around;
                margin-top: 1px;

                img {
                    width: 120px;
                    height: 70px;
                    border-radius: 3px;
                    object-fit: contain;
                    background: #ccc;
                }

                .conright {
                    width: calc(100% - 150px);
                    font-size: 14px;

                    p {
                        color: #333333;
                        font-weight: bold;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        line-height: 25px;
                        height: 25px;
                    }

                    span {
                        color: #646566;
                        line-height: 25px;
                        height: 25px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        display: block;
                    }

                    .centertime {
                        color: #969799;
                        font-size: 12px;
                        line-height: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }

    .suspend-btn {
        position: fixed;
        bottom: 15%;
        right: 3%;

        div {
            width: 50px;
            height: 50px;
            background: rgba(0, 204, 153, .8);
            border-radius: 50%;
        }

        .back-top {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 26px;
            }
        }

        .home-box {
            margin-top: 10px;
            padding-top: 6px;
            line-height: 15px;
            font-size: 12px;
            color: #fff;

            img {
                width: 20px;
                margin-left: 15px;
            }

            p {
                text-align: center;
            }
        }
    }

    // /deep/.support{
    //     color: #969799;
    //     margin-top: .3rem;
    // }
}
</style>