<template>
  <div class="moveBg" @mousemove="mouseMove" @mouseup="mouseUp" @mouseleave.stop="mouseLeave" :style="{
    pointerEvents: isBoxResizing || isBoxMoving ? 'auto' : 'none',
    zIndex: isFullScreen&&$store.state.openAppList[$store.state.openAppListIgndex].pid==app.pid ? 999:$store.state.openAppList[$store.state.openAppListIgndex].pid==app.pid? 98 : 88,
  }" v-if="!app.inquiry">
    <div class="box" :style="{
      left: nowRect.left + 'px',
      top: nowRect.top + 'px',
      width: nowRect.width + 'px',
      height: nowRect.height + 'px',
    }" :class="getExtBoxClasses()">
      <div class="box-top">
        <div class="box-top-left" @mousedown="resizeMouseDown"></div>
        <div class="box-top-center" @mousedown="resizeMouseDown"></div>
        <div class="box-top-right" @mousedown="resizeMouseDown"></div>
      </div>
      <div class="box-center">
        <div class="box-left">
          <div class="box-top-left" @mousedown="resizeMouseDown"></div>
          <div class="box-center-left" @mousedown="resizeMouseDown"></div>
          <div class="box-bottom-left" @mousedown="resizeMouseDown"></div>
        </div>

        <div class="box-center-center loader" @click="showThisApp">
          <div class="box-top-left" @mousedown="resizeMouseDown"></div>
          <div class="box-bottom-left" @mousedown="resizeMouseDown"></div>
          <div class="box-top-right" @mousedown="resizeMouseDown"></div>
          <div class="box-bottom-right" @mousedown="resizeMouseDown"></div>
          <div class="app-bar" :style="{ backgroundColor: app.titleBgColor }" @mousedown.stop="positionMouseDown"
            v-on:dblclick="appBarDoubleClicked">
            <div class="controll">
              <div class="close" @click.stop="closeApp"></div>
              <div class="min" @click.stop="hideApp" :class="app.reload ? 'full-disabled' : ''"></div>
              <div class="full" :class="app.disableResize ? 'full-disabled' : ''" @click="switchFullScreen"></div>
            </div>
            <div class="title" :style="{ color: app.titleColor }">
              {{ appData.module_title || app.module_title }}
            </div>
          </div>
          <div class="app-body"  v-if="!app.constraint">
            <KeepAlive>
              <component :is="app.component" :app="app" @api="appEvent"
                :active="isBoxResizing || isBoxMoving || $store.state.openAppList[$store.state.openAppListIgndex].pid != app.pid ? true : false"
                class="app-body-box"  @isRegister="isRegister"></component>
            </KeepAlive>
            <div class="app_map" v-if="$store.state.openAppList[$store.state.openAppListIgndex].pid!= app.pid"></div>
          </div>
           <!-- 强制注册 -->
          <div class="app-body" v-else>
            <Register @isRegister="isRegister"></Register>
          </div>
        </div>
        <div class="box-right">
          <div class="box-top-right" @mousedown="resizeMouseDown"></div>
          <div class="box-center-right" @mousedown="resizeMouseDown"></div>
          <div class="box-bottom-right" @mousedown="resizeMouseDown"></div>
        </div>
      </div>
      <div class="box-bottom">
        <div class="box-bottom-left" @mousedown="resizeMouseDown"></div>
        <div class="box-bottom-center" @mousedown="resizeMouseDown"></div>
        <div class="box-bottom-right" @mousedown="resizeMouseDown"></div>
      </div>
    </div>
  </div>
  <!-- 询问注册 -->
  <div v-else>
    <el-dialog title="温馨提示" :visible.sync="dialogVisible" width="420px" :center="true" :show-close="false" top="30vh"
      @close="cancelShow">
      <div v-html="app.configure.compulsory_text"></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelShow">取 消</el-button>
        <el-button type="primary" @click="affirmShow">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Blank from "@/views//MacPC/dom/blank.vue"
import DemoWeb from "@/views/MacPC/dom/web.vue"
import Articlelist from "@/views/MacPC/dom/news/Articlelist.vue"
import ArticleDetails from "@/views/MacPC/dom/news/articleDetails.vue"
import LiveList from "@/views/MacPC/dom/liveList.vue"
import Register from "@/views/MacPC/dom/Register.vue"
import PhotoGallery from "@/views/MacPC/dom/PhotoGallery.vue"
import Schedule from "@/views/MacPC/dom/Schedule/index.vue"
import ScheduleInfo from "@/views/MacPC/dom/Schedule/newdetail.vue"
import AttendIntro from "@/views/MacPC/dom/attend/details.vue"
import Attend from "@/views/MacPC/dom/attend/index.vue"
import Cooperation from "@/views/MacPC/dom/cooperation.vue"
import Exhibition from "@/views/MacPC/dom/Exhibition.vue"
import Coopimg from "@/views/MacPC/dom/coopimg"
import Addendum from "@/views/MacPC/dom/Addendum"
import FilEdownload from "@/views/MacPC/dom/Filedownload"
import ConferenceAddress from "@/views/MacPC/dom/ConferenceAddress"
import Antiepidemic from "@/views/MacPC/dom/hotel/antiepidemic"
import Hotel from "@/views/MacPC/dom/hotel/index"
import RegPayment from "@/views/MacPC/dom/RegPayment"
import Invoice from "@/views/MacPC/dom/invoice"
import Video from "@/views/MacPC/dom/video"
export default {
  components: {
    Blank,
    DemoWeb,
    Articlelist,
    ArticleDetails,
    Register,
    LiveList,
    PhotoGallery,
    Schedule,
    ScheduleInfo,
    AttendIntro,
    Attend,
    Cooperation,
    Exhibition,
    Coopimg,
    Addendum,
    FilEdownload,
    ConferenceAddress,
    Antiepidemic,
    Hotel,
    RegPayment,
    Invoice,
    Video,
  },
  props: {
    app: Object,
  },
  watch: {
    app() {
      this.appData = Object({}, this.app);
    },
  },
  data() {
    return {
      appData: {
        title: "",
      },
      defaultIndex: 10,
      activeIndex: 20,
      isBoxMoving: false,
      startPosition: { x: 0, y: 0 },
      nowRect: {
        left: 100,
        top: 100,
        width: 200,
        height: 800,
      },
      startRect: {
        left: 0,
        top: 0,
        width: 0,
        height: 0,
      },
      isBoxResizing: false,
      moveDirection: false,
      isMaxShowing: false,
      isFullScreen: false,
      dialogVisible: true,
    };
  },
  created() {
    this.appData = Object({}, this.app);
    this.setReact();
  },
  methods: {
    setReact() {
      let w = document.body.clientWidth;
      let h = document.body.clientHeight;
      this.nowRect.width = this.app.width > 0 ? this.app.width : w / 2;
      this.nowRect.height = this.app.height > 0 ? this.app.height : w / 3;
      this.nowRect.left = w / 2 - this.nowRect.width / 2;
      this.nowRect.top = (h - this.nowRect.height) / 2;
    },
    /**
     * @description: 监听APP发送的事件 转发或处理到桌面组件中
     */
    appEvent(e) {
      switch (e.event) {
        case "windowMaxSize":
          if (this.app.disableResize) {
            return;
          }
          this.isMaxShowing = true;
          this.isFullScreen = false;
          break;
        case "windowNormalSize":
          if (this.app.disableResize) {
            return;
          }
          this.isMaxShowing = false;
          this.isFullScreen = false;
          break;
        case "windowFullSize":
          if (this.app.disableResize) {
            return;
          }
          this.isFullScreen = true;
          this.isMaxShowing = true;
          break;
        case "windowMinSize":
          this.hideApp();
          break;
        case "windowClose":
          this.closeApp();
          break;
        case "openApp":
          if (e.data && e.app) {
            this.$store.commit("openWithData", {
              app: this.tool.getAppByKey(e.app),
              data: e.data,
            });
          } else {
            this.$store.commit("openApp", this.tool.getAppByKey(e.app));
          }
          break;
        case "closeApp":
          if (e.pid) {
            this.$store.commit("closeWithPid", e.pid);
          }
          if (e.app) {
            this.$store.commit("closeApp", this.tool.getAppByKey(e.app));
          }
          break;
        case "setWindowTitle":
          this.appData.title = e.title || this.app.title;
          break;
        default:
      }
    },
    /**
     * @description: 关闭当前应用
     */
    closeApp() {
      this.$store.commit("closeApp", this.app);
    },
    /**
     * @description: 隐藏当前应用
     */
    hideApp() {
      if(this.app.reload){
        return
      }
      this.$store.commit("hideApp", this.app);
    },
    showThisApp() {
      this.$store.commit("showApp", this.app);
    },
    /**
     * @description: 全屏切换
     */
    switchFullScreen() {
      if (this.app.disableResize) {
        return;
      }
      this.isFullScreen = !this.isFullScreen;
      if (this.isFullScreen) {
        this.isMaxShowing = true;
      } else {
        this.isMaxShowing = false;
      }
    },
    /**
     * @description: 返回应用配置的样式类
     */
    getExtBoxClasses() {
      let str = "";
      if (!this.isBoxResizing && !this.isBoxMoving) {
        str += "box-animation ";
      }
      if (this.isMaxShowing) {
        str += "isMaxShowing ";
      }
      if (this.isFullScreen) {
        str += "isFullScreen ";
      }
      if (this.app.disableResize) {
        str += "resize-disabled ";
      }
      if (
        this.$store.state.openAppList[this.$store.state.openAppListIgndex]
          .pid == this.app.pid
      ) {
        str += "isTop ";
      }
      return str;
    },
    /**
     * @description: 标题栏被双击 缩放
     */
    appBarDoubleClicked() {
      if (this.app.disableResize) {
        return;
      }
      this.isMaxShowing = !this.isMaxShowing;
      if (!this.isMaxShowing) {
        this.isFullScreen = false;
      }
    },
    /**
     * @description: 鼠标按下
     */
    positionMouseDown(e) {
      // console.warn("positionMouseDown")
      this.showThisApp();
      if (this.isFullScreen || this.isMaxShowing) {
        return;
      }
      this.startRect = {
        left: this.nowRect.left,
        right: this.nowRect.right,
        top: this.nowRect.top,
        bottom: this.nowRect.bottom,
      };
      this.startPosition.x = e.clientX;
      this.startPosition.y = e.clientY;
      this.isBoxMoving = true;
    },
    mouseUp() {
      this.isBoxMoving = false;
      this.isBoxResizing = false;
      this.moveDirection = false;
    },
    mouseLeave() {
      this.isBoxMoving = false;
      this.isBoxResizing = false;
      this.moveDirection = false;
    },
    mouseMove(e) {
      // 鼠标移动事件
      if (this.isBoxResizing) {
        this.isFullScreen = false;
        this.isMaxShowing = false;
        switch (this.moveDirection) {
          case "box-top-left":
            this.nowRect.height =
              this.nowRect.height + (this.nowRect.top - e.clientY);
            this.nowRect.top = e.clientY;
            this.nowRect.width =
              this.nowRect.width + (this.nowRect.left - e.clientX);
            this.nowRect.left = e.clientX;
            break;
          case "box-top-center":
            this.nowRect.height =
              this.nowRect.height + (this.nowRect.top - e.clientY);
            this.nowRect.top = e.clientY;
            break;
          case "box-top-right":
            this.nowRect.height =
              this.nowRect.height + (this.nowRect.top - e.clientY);
            this.nowRect.top = e.clientY;
            this.nowRect.width =
              this.nowRect.width +
              (e.clientX - (this.nowRect.left + this.nowRect.width));
            break;
          case "box-center-left":
            this.nowRect.width =
              this.nowRect.width + (this.nowRect.left - e.clientX);
            this.nowRect.left = e.clientX;
            break;
          case "box-bottom-left":
            this.nowRect.width =
              this.nowRect.width + (this.nowRect.left - e.clientX);
            this.nowRect.left = e.clientX;
            this.nowRect.height =
              this.nowRect.height +
              (e.clientY - (this.nowRect.top + this.nowRect.height));
            break;
          case "box-bottom-center":
            this.nowRect.height =
              this.nowRect.height +
              (e.clientY - (this.nowRect.top + this.nowRect.height));
            break;
          case "box-center-right":
            this.nowRect.width =
              this.nowRect.width +
              (e.clientX - (this.nowRect.left + this.nowRect.width));
            break;
          case "box-bottom-right":
            this.nowRect.height =
              this.nowRect.height +
              (e.clientY - (this.nowRect.top + this.nowRect.height));
            this.nowRect.width =
              this.nowRect.width +
              (e.clientX - (this.nowRect.left + this.nowRect.width));
            break;
          default:
        }
        return;
      }
      if (this.isBoxMoving) {
        this.isFullScreen = false;
        this.isMaxShowing = false;
        this.nowRect.left =
          this.startRect.left + (e.clientX - this.startPosition.x);
        this.nowRect.top =
          this.startRect.top + (e.clientY - this.startPosition.y);
        return;
      }
    },
    resizeMouseDown(e) {
      if (this.app.disableResize) {
        return;
      }
      this.showThisApp();
      if (this.isFullScreen || this.isMaxShowing) {
        return;
      }
      this.startRect = {
        left: this.nowRect.left,
        top: this.nowRect.top,
        width: this.nowRect.width,
        height: this.nowRect.height,
      };
      this.startPosition.x = e.clientX;
      this.startPosition.y = e.clientY;
      this.isBoxResizing = true;
      this.moveDirection = e.target.className;
    },
    // 强制注册
    isRegister() {
      this.$store.commit("Registered");
    },
    // 询问注册
    affirmShow() {
      this.$store.commit("affirmShow", this.app);
    },
    cancelShow() {
      this.$store.commit("cancelShow", this.app);
    }
  },
};
</script>
<style scoped lang="scss">
.moveBg {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .isTop {
    .box-center-center {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5) !important;
      filter: none !important;
    }
  }

  .isMaxShowing {
    left: -5px !important;
    top: 23px !important;
    width: calc(100vw + 10px) !important;
    height: calc(100vh - 89px) !important;
  }

  .isFullScreen {
    position: fixed !important;
    z-index: 999 !important;
    left: -5px !important;
    top: -5px !important;
    width: calc(100vw + 10px) !important;
    height: calc(100vh + 10px) !important;
  }

  .isMaxShowing .box-center-center,
  .isFullScreen .box-center-center {
    border-radius: 0px !important;
  }

  .box-animation {
    transition: width 0.1s, height 0.1s, left 0.1s, right 0.1s, top 0.1s,
      bottom 0.1s;
  }

  .resize-disabled {

    .box-top,
    .box-top-left,
    .box-top-center,
    .box-top-right,
    .box-left,
    .box-center-left,
    .box-center-right,
    .box-right,
    .box-bottom,
    .box-bottom-left,
    .box-bottom-center,
    .box-bottom-right {
      cursor: default !important;
    }
  }

  .box {
    --resize: 5px;
    --resize-cm: 10px;
    --resize-bg: transparent;
    --resize-main: transparent;
    --resize-bg-main: transparent;
  }

  .box {
    display: flex;
    flex-direction: column;
    position: absolute;
    pointer-events: auto;

    .box-top {
      display: flex;
      flex-direction: row;

      .box-top-left {
        width: var(--resize-cm);
        height: var(--resize);
        background: var(--resize-bg);
        cursor: nw-resize;
      }

      .box-top-center {
        width: calc(100% - 20px);
        height: var(--resize);
        background: var(--resize-bg-main);
        cursor: n-resize;
      }

      .box-top-right {
        width: var(--resize-cm);
        height: var(--resize);
        background: var(--resize-bg);
        cursor: ne-resize;
      }
    }

    .box-center {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      align-items: center;
      height: calc(100% - 10px);
      .loader {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        width: 100%;
      }

      .box-left {
        display: flex;
        flex-direction: column;
        height: 100%;

        .box-top-left {
          height: var(--resize-cm);
          width: var(--resize);
          background: var(--resize-bg);
          cursor: nw-resize;
        }

        .box-center-left {
          width: var(--resize);
          height: calc(100% - 20px);
          background: var(--resize-bg-main);
          cursor: w-resize;
        }

        .box-bottom-left {
          height: var(--resize-cm);
          width: var(--resize);
          background: var(--resize-bg);
          cursor: sw-resize;
        }
      }

      .box-center-center {
        height: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        box-shadow: 0px 0px 3px #999;
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(20px);
        overflow: hidden;
        filter: grayscale(1) brightness(0.9);
        position: relative;

        .box-top-left {
          position: absolute;
          top: 0px;
          left: 0px;
          width: var(--resize-cm);
          height: var(--resize-cm);
          background: var(--resize-bg);
          z-index: 99;
          cursor: nw-resize;
        }

        .box-top-right {
          position: absolute;
          top: 0px;
          right: 0px;
          width: var(--resize-cm);
          height: var(--resize-cm);
          background: var(--resize-bg);
          z-index: 99;
          cursor: ne-resize;
        }

        .box-bottom-right {
          position: absolute;
          bottom: 0px;
          right: 0px;
          width: var(--resize-cm);
          height: var(--resize-cm);
          background: var(--resize-bg);
          z-index: 99;
          cursor: se-resize;
        }

        .box-bottom-left {
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: var(--resize-cm);
          height: var(--resize-cm);
          background: var(--resize-bg);
          z-index: 99;
          cursor: sw-resize;
        }

        .app-bar {
          height: 40px;
          background: rgba(255, 255, 255, 0.5);
          backdrop-filter: blur(20px);
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          border-bottom: 1px solid #efefef;

          .title {
            flex-grow: 1;
            text-align: center;
            margin-right: 84px;
            font-weight: 500;
            text-shadow: none;
            font-size: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: move;
            color: #333;
          }

          .controll {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 15px;

            div {
              border-radius: 100%;
              height: 14px;
              width: 14px;
              margin-right: 8px;
              cursor: pointer;
            }

            .close {
              background: #fc605c;
            }

            .close:hover {
              background: #cc2c26;
            }

            .min {
              background: #fcbb40;
            }

            .min:hover {
              background: #c28719;
            }

            .full {
              background: #34c648;
            }

            .full:hover {
              background: #1f942e;
            }

            .full-disabled {
              background: #ccc !important;
            }
          }
        }

        .app-body {
          // flex-grow: 1;
          display: flex;
          // flex-direction: column;
          justify-content: center;
          position: relative;
          // align-items: center;
          height: calc(100% - 40px);
          // overflow-y: auto;
          // background: #fff;
          .app_map{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 99999;
          }
          .app-body-box {
            height: 100%;
            width: 100%;
            ::-webkit-scrollbar {
              display: none;
            }
          }
        }
      }

      .box-right {
        display: flex;
        flex-direction: column;
        height: 100%;

        .box-top-right {
          height: var(--resize-cm);
          width: var(--resize);
          background: var(--resize-bg);
          cursor: ne-resize;
        }

        .box-center-right {
          width: var(--resize);
          height: calc(100% - 20px);
          background: var(--resize-bg-main);
          cursor: e-resize;
        }

        .box-bottom-right {
          height: var(--resize-cm);
          width: var(--resize);
          background: var(--resize-bg);
          cursor: se-resize;
        }
      }
    }

    .box-bottom {
      display: flex;
      flex-direction: row;

      .box-bottom-left {
        width: var(--resize-cm);
        height: var(--resize);
        background: var(--resize-bg);
        cursor: sw-resize;
      }

      .box-bottom-center {
        height: var(--resize);
        background: var(--resize-bg-main);
        cursor: s-resize;
        flex-grow: 1;
      }

      .box-bottom-right {
        width: var(--resize-cm);
        height: var(--resize);
        background: var(--resize-bg);
        cursor: se-resize;
      }
    }
  }
}

/deep/.el-dialog__wrapper {
  font-size: 14px;

  .el-dialog {
    border-radius: 10px;
  }

  .el-dialog__body {
    padding: 15px 20px;
    max-height: 156px;
    overflow-y: scroll;
  }
}
</style>
