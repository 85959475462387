<template translate="no">
    <!-- <div class="loading">
      <img src="../../assets/loading2(1).gif" style="width:100px;height:100px;">
    </div> -->
    <div class="loading_home flex">
       <div class="loading-box">
         <div class="dot"></div>
         <div class="dot"></div>
         <div class="dot"></div>
         <div class="dot"></div>
         <div class="dot"></div>
       </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      ispc:true,
    }
  },
  created(){
    let is_mobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    this.ispc = is_mobile?false:true;
  }
}
</script>

<style lang="less" scoped>
/* .loading {
  position:absolute; */
  /* background: url('../../assets/logo-_7.gif') center center no-repeat; */
  /* width: 100%; */
  /* height: 70%; */
  /* top: 40%;
  left: 50%;
  transform: translate(-50%,-50%);
  text-align: center;
  z-index: 2;
} */

.loading_home{
    // width: 100%;
    // height: 100%;
    text-align: center;
    position:absolute;
    top: 40%;
    bottom: -40%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    z-index: 2;
    .loading-box{
      margin: 0 auto;
    }
    .dot {
      width: 24px;
      height: 24px;
      background: #008FB2;
      border-radius: 100%;
      display: inline-block;
      animation: slide 1s infinite;
    }
    .dot:nth-child(1) {
      animation-delay: 0.1s;
      background: #009B9E;
    }
    .dot:nth-child(2) {
      animation-delay: 0.2s;
      background: #00A77D;
    }
    .dot:nth-child(3) {
      animation-delay: 0.3s;
      background: #00B247;
    }
    .dot:nth-child(4) {
      animation-delay: 0.4s;
      background: #5AB027;
    }
    .dot:nth-child(5) {
      animation-delay: 0.5s;
      background: #A0B61E;
    }
    @-moz-keyframes slide {
      0% {
        transform: scale(1);
      }
      50% {
        opacity: 0.3;
        transform: scale(2);
      }
      100% {
        transform: scale(1);
      }
    }
    @-webkit-keyframes slide {
      0% {
        transform: scale(1);
      }
      50% {
        opacity: 0.3;
        transform: scale(2);
      }
      100% {
        transform: scale(1);
      }
    }
    @-o-keyframes slide {
      0% {
        transform: scale(1);
      }
      50% {
        opacity: 0.3;
        transform: scale(2);
      }
      100% {
        transform: scale(1);
      }
    }
    @keyframes slide {
      0% {
        transform: scale(1);
      }
      50% {
        opacity: 0.3;
        transform: scale(2);
      }
      100% {
        transform: scale(1);
      }
    }
}
</style>