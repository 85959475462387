<template>
  <div>
    <transition name="fade">
      <div class="loading" @click="fullScreen" v-if="loadingShow">
        <div class="logo"><img class="iconfont" src="@/assets/img/home/qwzlogo.svg" alt=""></div>
        <div class="progress" :style="{ width: showProgress ? '300px' : 0 }">
          <div :style="{ width: progress + '%' }"></div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <Wechatqrcode v-if="isQrcode" :empowerStatus='empowerStatus' :qrurl="qrurl" @refreshQrcode="refreshQrcode">
      </Wechatqrcode>
    </transition>
    <transition name="fade">
      <Login v-if="isLogin" @logined="logined" :authorization='authorization' :bgkv="bgkv"></Login>
    </transition>
    <transition name="fade">
      <Register v-if="isRegister" @register="register" :site_id="site_id"></Register>
    </transition>

  </div>

</template>

<style scoped lang="scss">
.loading {
  background-color: rgba(250, 250, 250, .8);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: -100px;
  z-index: 99999;
  backdrop-filter: blur(100px);

  .logo {
    display: flex;

    .iconfont {
      width: 200px;
    }
  }

  .progress {
    margin-top: 50px;
    width: 200px;
    background-color: rgba(0, 0, 0, 0.2);
    height: 6px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;

    div {
      width: 20%;
      border-radius: 20px;
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }
  }
}
</style>
<script>
import { mapState } from "vuex";
import Login from "@/components/mac/Login.vue";
import Wechatqrcode from "@/components/mac/wechatqrcode.vue";
import Register from "@/components/mac/Register.vue";
export default {
  data() {
    return {
      progress: 0,
      showProgress: false,
      authorization: [],
      site_id: "",
      isLogin: false,
      isQrcode: false,
      loadingShow: false,
      isRegister: false,//是否全局注册
      time: null, //循环获取授权状态
      empowerStatus: false, //二维码是否过期
      wechatKey: "", //微信授权key
      qrurl: "",
      identity_url: '',
      wx_User: '',
    };
  },
  props: ['bgkv'],
  components: {
    Login,
    Wechatqrcode,
    Register
  },
  computed: {
    ...mapState(["token", "userinfo"]),
  },
  created() {
    setTimeout(() => {
      this.loadingShow = true;
      this.showProgress = true;
      this.updateProgress();
    }, 1000);
  },
  methods: {
    // 点击触发全屏效果
    fullScreen() {
      var docElm = document.documentElement;
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      }
    },
    GetAuthorizationSwitch() {
      this.$store
        .dispatch("get_authorization_switch", sessionStorage.getItem("siteUrl"))
        .then((res) => {
          if (res.data.code == 200) {
            this.authorization = res.data.data;
            this.loadingShow = false;
            this.showProgress = false;
            this.site_id = this.authorization.site_id;
            this.$cookies.set("site_id", this.site_id);
            this.$cookies.isKey("microtoken") &&
              localStorage.getItem("wxmessage")
              ? this.$store.commit("changge_loginStatus", true)
              : this.$store.commit("changge_loginStatus", false);
            // 是否开启微信授权登录
            if (this.authorization.wechat_status == 1) {
              if (
                this.$cookies.isKey("microtoken") &&
                localStorage.getItem("wxmessage")
              ) {
                if (this.authorization.password_status == 1) {
                  this.getPasswordExhibit();
                }
              } else {
                this.get_pc_login_key();
                this.isQrcode = true;
              }
            } else if (this.authorization.identity_grant_status == 1) {
              //获取身份赋予跳转链接
              this.$store.dispatch('get_identity_info_exhibit', {
                site_id: this.authorization.site_id
              }).then(res => {
                if (res.data.code == 200) {
                  this.identity_url = res.data.data.identity_url
                }
              })
              // //开启身份赋予 是否开启密码观看
              this.wx_User = localStorage.getItem('wxmessage') ? JSON.parse(localStorage.getItem('wxmessage')) : null//获取用户信息
              // if(!this.$cookies.isKey('microtoken')) {//没有身份赋予过
              if (this.$route.query.token) {//判断url里面有没有token
                this.$cookies.set('microtoken', this.$route.query.token)
                this.$store.dispatch('verify_identity_user_info', {
                  site_id: this.authorization.site_id
                }).then(res => {
                  if (res.data.code == 200 && res.data.message == '验证通过!') {
                    if (localStorage.getItem('wxmessage')) {
                      localStorage.removeItem('wxmessage')
                    }
                    localStorage.setItem('wxmessage', JSON.stringify(res.data.data))
                    this.wx_User = res.data.data
                    if (this.authorization.password_status == 1) {//开启密码观看
                      this.getPasswordExhibit()
                    }
                  } else {
                    window.location.href = this.identity_url
                  }
                })

              } else {
                this.$store.dispatch('verify_identity_user_info', {
                  site_id: this.authorization.site_id
                }).then(res => {
                  if (res.data.code == 200 && res.data.message == '验证通过!') {
                    if (localStorage.getItem('wxmessage')) {
                      localStorage.removeItem('wxmessage')
                    }
                    localStorage.setItem('wxmessage', JSON.stringify(res.data.data))
                    this.wx_User = res.data.data
                    if (this.authorization.password_status == 1) {//开启密码观看
                      this.getPasswordExhibit()
                    }
                  } else {
                    window.location.href = this.identity_url
                  }
                })

              }
            } else if (this.authorization.password_status == 1) {
              this.getPasswordExhibit();
              // 是否开启身份赋予
            } else {
              this.register()
            }
          }
        });
    },
    //C端微信登录授权key
    get_pc_login_key() {
      this.$store.dispatch("get_pc_login_key").then((res) => {
        if (res.data.code == 200) {
          if (this.time) {
            clearInterval(this.time);
            this.time = null;
          }
          this.wechatKey = res.data.data.key;
          this.empowerStatus = false;
          this.qrurl = `https://test-link.lighos.com/auth/oauth/state?key=${this.wechatKey}`;
          this.time = setInterval(() => {
            this.get_pc_login_token();
          }, 1000);
        }
      });
    },
    get_pc_login_token() {
      this.$store
        .dispatch("get_pc_login_token", {
          key: this.wechatKey,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.$cookies.set("microtoken", res.data.data.token);
            localStorage.setItem("wxmessage", JSON.stringify(res.data.data.user_info));
            localStorage.setItem("wmUserInfo", JSON.stringify({ userId: `wx${res.data.data.user_info.id}`, userTag: "微信用户", projectVersion: "1.0.1", env: "dev", }));
            // window.localStorage.wmUserInfo = JSOfN.stringify();
            this.isQrcode = false;
            if (this.authorization.password_status) {
              this.getPasswordExhibit();
            } else {
              this.register()
            }
            if (this.time) {
              clearInterval(this.time);
              this.time = null;
            }
          }
          if (res.data.code == 100) {
            if (this.time) {
              clearInterval(this.time);
              this.time = null;
            }
            this.empowerStatus = true;
          }
        });
    },
    // 获取授权密码认证
    getPasswordExhibit() {
      this.$store
        .dispatch("get_password_exhibit", {
          site_id: this.site_id,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.isLogin = true;
            localStorage.setItem("user_name", res.data.data.title);
          }
          if (res.data.code == 20002) {
            this.register()
          }
        });
    },
    logined() {
      this.isLogin = false
      this.register()
    },
    refreshQrcode() {
      this.get_pc_login_key();
    },
    // 加载进度
    updateProgress() {
      this.progress += parseInt(Math.random() * 20);
      if (this.progress >= 100) {
        this.progress = 100;
        this.GetAuthorizationSwitch();
      } else {
        setTimeout(() => this.updateProgress(), 100);
      }
    },
    /**
     * @description: 判断是否全局表单展示
     */
    register() {
      this.$store
        .dispatch("get_form_global_registration", {
          site_url: sessionStorage.getItem("siteUrl"),
        })
        .then((res) => {
          if (res.data.code == 200) {
            if (res.data.data.global_sign_up_form_login) {
              // 判断是否已将注册过
              if (res.data.data.is_register == 1) {
                setTimeout(() => this.$emit("loaded"), 500);
              } else {
                this.isRegister = true
              }
            } else {
              setTimeout(() => this.$emit("loaded"), 500);
            }
          }
        });
    },
  },
};
</script>