<template>
    <div class="deticleBox" id="scroll" :style="{ '--themecolor': themecolors }">
        <div style="min-height:95%;">
            <van-loading :color="themecolors" :text-color="themecolors" v-if="Loading" />
            <van-empty :image="require('@/assets/null.svg')" v-else-if="news_info == {}" />
            <div class="deticleCon" v-else>
                <div class="top">
                    <p>{{ news_info.new_title }}</p>
                    <div class="flex" style="margin-left:10px;color:#969799;"><span>{{ news_info.author }}
                            {{ news_info.created_at }}</span>
                        <div class="flex">
                            <van-icon name="eye-o" style="font-size:15px;" />&nbsp;{{ news_info.views_count }}
                        </div>
                    </div>
                </div>
                <div class="content" v-html="news_info.text"></div>
                <div class="bottom flex">
                    <p :style="(news_list.length > 0) && (news_list[0].id == current_id) ? 'color:#999;' : ''"
                        @click.stop="news_list[0].id != current_id && prev()">上一篇</p>
                    <p :style="(news_list.length > 0) && (news_list[news_list.length - 1].id == current_id) ? 'color:#999;' : ''"
                        @click.stop="news_list[news_list.length - 1].id != current_id && next()">下一篇</p>
                </div>
            </div>
        </div>
        <Support></Support>
    </div>
</template>
<script>
import Support from '@/components/support';
import merge from 'webpack-merge';
import { mapState } from 'vuex'
export default {
    components: {
        Support
    },
    data() {
        return {
            news_info: {},
            Loading: true,
            news_list: [],
            current_id: null,
        }
    },
    props: {
        app: Object,
    },
    created() {
        this.params = this.app.data
        this.current_id = this.params.id;
    },
    mounted() {
        this.show_news_info();
        if (!this.app.exhibition_skip) {
            this.news_information_list();
        }else{
            this.showroom_news_list()
        }

    },
    computed: {
        ...mapState(['themecolors'])
    },
    methods: {
        prev() {
            for (let i = 0; i < this.news_list.length; i++) {
                if (this.news_list[i].id == this.current_id) {
                    this.current_id = this.news_list[i - 1].id;
                    this.$router.push({
                        query: merge(this.$route.query, { 'id': this.current_id })
                    })
                    this.show_news_info();
                    return
                }
            }
        },
        next() {
            for (let i = 0; i < this.news_list.length; i++) {
                if (this.news_list[i].id == this.current_id) {
                    this.current_id = this.news_list[i + 1].id;
                    this.$router.push({
                        query: merge(this.$route.query, { 'id': this.current_id })
                    })
                    this.show_news_info();
                    return
                }
            }
        },
        // 展厅展示新闻资讯分类列表
        showroom_news_list() {
            this.$store.dispatch("showroom_news_list", {
                site_id: this.params.site_id,
                show_grouping: this.app.show_grouping
            }).then(res => {
                if (res.data.code === 200) {
                    this.news_list = res.data.data.data.module_content;
                }
            })
        },
        news_information_list() {
            this.$store.dispatch("news_information_list", {
                site_id: this.params.site_id,
                module_id: this.params.module_id,
                page: 1,
                page_num: 20,
            }).then(res => {
                if (res.data.code == 200) {
                    this.news_list = res.data.data.module_content.data;
                }
            })
        },
        show_news_info() {
            this.Loading = true;
            this.$store.dispatch("show_news_info", {
                id: this.current_id,
            }).then(res => {
                this.Loading = false;
                if (res.data.code == 200) {
                    this.news_info = res.data.data;
                }
            })
        },
    }
}
</script>
<style lang="less" scoped>
@Color: var(--themecolor);

/deep/.van-loading {
    position: absolute;
    top: -10%;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

/deep/.van-loading {
    top: 40%;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bottom {
    font-size: 12px;
    margin: 20px 10px;
    cursor: pointer;
    color: @Color;
}

.deticleBox::-webkit-scrollbar {
    display: none;
}

.deticleBox {
    max-width: 420px;
    width: 100%;
    height: 100%;
    background: #F7F8FA;
    overflow-y: scroll;

    .deticleCon {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        padding: 0 15px;
        overflow-x: hidden;

        .top {
            font-size: 14px;
            padding-top: 10px;
            padding-bottom: 10px;

            p {
                color: #323233;
                font-size: 18px;
                overflow: hidden;
                word-break: break-all;
                margin-bottom: 10px;
            }

            span {
                color: #969799;
                font-size: 14px;
            }
        }

        .content {
            font-size: 14px;
            word-break: break-all;

            p {
                line-height: 24px;
                color: #646566;
                word-break: break-all;
            }
        }
    }
}
</style>