var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-page-box",style:(_vm.cover_set.show_cover && _vm.coverStatus == 0 ? 'z-index:222;' : '')},[(_vm.isLoading)?_c('loading',{staticStyle:{"margin-top":"210px"}}):_vm._e(),(_vm.overall == 1 && !_vm.isLoading)?_c('Loginindex',{attrs:{"page_style":_vm.page_style},on:{"registered":_vm.registered}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.overall == 0 && !_vm.isLoading),expression:"overall == 0 && !isLoading"}],staticClass:"main_home"},[(_vm.err_code == 3001)?_c('van-empty',{attrs:{"image":require('@/assets/closed.svg')}}):_vm._e(),(_vm.err_code == 3002)?_c('van-empty',{attrs:{"image":require('@/assets/deled.svg')}}):_vm._e(),(_vm.err_code == 3003)?_c('van-empty',{attrs:{"image":require('@/assets/not_started.svg')}}):_vm._e(),(_vm.err_code == 3004)?_c('van-empty',{attrs:{"image":require('@/assets/finished.svg')}}):_vm._e(),(_vm.err_code == 3000)?_c('van-empty',{attrs:{"image":require('@/assets/station_null.svg')}}):_vm._e(),(_vm.err_code == 200)?_c('div',{staticClass:"home-box",attrs:{"id":"scroll"}},[(_vm.cover_set.show_cover && _vm.coverStatus == 0)?_c('div',{staticClass:"micro-sitecover"},[_c('p',[_vm._v(_vm._s(_vm.cover_set.count_down)+"s")]),_c('img',{attrs:{"src":_vm.cover_set.micro_sitecover,"alt":""}})]):_c('div',{ref:"imageToFile",staticClass:"home is-main-width",style:({
        background: _vm.background_type == 2 ? ("url('" + (_vm.page_style.background_setting.background_picture_url) + "')") : _vm.page_style.background_setting.background_color,
        backgroundSize: _vm.background_image_type ? '100% 100%' : '100%',
      }),attrs:{"id":"my-node"}},[_c('div',{staticClass:"home-top",style:({ height: _vm.page_style.carousel.banner_area_height / 100 + 'rem', })},[(_vm.page_style.carousel.hide_banner == 0)?_c('van-swipe',{staticClass:"my-swipe",style:({ height: _vm.page_style.carousel.banner_area_height / 100 + 'rem', }),attrs:{"autoplay":Number(_vm.page_style.carousel.banner_duration) * 1000,"indicator-color":"white"}},_vm._l((_vm.page_style.carousel.carousel_link),function(i,j){return _c('van-swipe-item',{key:j + 0.5,on:{"click":function($event){return _vm.bannerSkip(i)}}},[_c('img',{staticClass:"swipe-img",staticStyle:{"height":"100%","width":"100%"},attrs:{"src":i.banner_img_url}})])}),1):_vm._e()],1),_c('div',{staticClass:"home-scoll",style:({ minHeight: _vm.showUserheader && (_vm.user_header == 1) ? 'calc(100vh - ' + _vm.page_style.carousel.banner_area_height / 100 + 'rem - .4rem)' : 'calc(100vh - ' + _vm.page_style.carousel.banner_area_height / 100 + 'rem )' })},[(_vm.site_model == 1)?_c('div',{staticClass:"home-bottom"},[_vm._l((_vm.module_content),function(item,index){return _c('div',{key:index + 'B',staticClass:"module",style:({
              flex: ("0 0 " + (Number(100 / _vm.module_columns)) + "%"),
              width: item.width + 'rem',
              height: item.height + 'rem',
              zIndex: item.module_level,
              padding: _vm.page_style.page_margins == 1 ? '.05rem' : '0',
            }),on:{"click":function($event){$event.stopPropagation();return _vm.constraintmoduleClick(item, _vm.module_content)}}},[_c('div',{staticClass:"item-box-default flex-center",style:({
                width: (_vm.page_style.page_margins == 1 ? item.module_size.width > 1 ? item.module_size.w + 0.1 * (item.module_size.width - 1) : item.module_size.w : item.module_size.w) + 'rem',
                height: (_vm.page_style.page_margins == 1 ? item.module_size.height > 1 ? item.module_size.h + 0.1 * (item.module_size.height - 1) : item.module_size.h : item.module_size.height > 1 ? item.module_size.h * item.module_size.height : item.module_size.h) + 'rem',
                background: item.module_background_color.selected == 1 ? ("url(" + (item.module_background_color.module_background_url) + ")") : '',
                transform: ("scale(" + (item.module_size.zoom != '' ? Number(item.module_size.zoom) : '1') + ") rotateZ(" + (Number(item.module_size.spin) != '' ? Number(item.module_size.spin) : '0') + "deg)"),
                backgroundColor: item.module_background_color.selected == 3 ? item.module_background_color.customize : '',
                backgroundSize: '100%',
                borderRadius: _vm.radius[item.module_shape - 1],
              })},[_c('div',{staticStyle:{"width":"100%"}},[(item.icon)?_c('div',{staticClass:"img-box flex-center constraint_img_box"},[_c('img',{attrs:{"src":item.icon,"alt":""}})]):_vm._e(),_c('p',{directives:[{name:"show",rawName:"v-show",value:(item.main_title_show_hidden == 1),expression:"item.main_title_show_hidden == 1"}],style:({ color: item.main_title_color, })},[_vm._v(" "+_vm._s(item.module_title)+" ")]),_c('p',{staticClass:"subtitle",style:({ color: item.subtitle_color, })},[_vm._v(" "+_vm._s(item.subtitle)+" ")])])])])}),_c('van-dialog',{attrs:{"confirmButtonText":"是","cancelButtonText":"否","confirmButtonColor":_vm.themecolors,"show-cancel-button":""},on:{"confirm":_vm.confirm,"cancel":_vm.cancel},model:{value:(_vm.dialog_show),callback:function ($$v) {_vm.dialog_show=$$v},expression:"dialog_show"}},[_c('div',{staticClass:"dialog-title"},[_vm._v("温馨提示")]),_c('div',{staticClass:"dialog-content",domProps:{"innerHTML":_vm._s(_vm.dialog_content)}})])],2):_vm._e(),(_vm.site_model == 2)?_c('div',{staticClass:"fileModel"},[(_vm.site_model == 2)?_c('div',{class:!_vm.fileLayout ? 'home-bottom modle-phone-box' : 'home-bottom modle-phone-box file-phone-box',style:({
                height: _vm.main_height / 100 + 'rem',
                minHeight: !_vm.fileLayout ? '0px' : '542px',
              })},[(!_vm.fileLayout)?_c('div',_vm._l((_vm.module_content),function(item,index){return _c('div',{key:index,staticClass:"module vdr",style:({
                  width: (item.module_size.width * _vm.proportionNumber) / 100 + 'rem',
                  height: (item.module_size.height * _vm.proportionNumber) / 100 + 'rem',
                  zIndex: item.module_size.index,
                  padding: _vm.page_style.page_margins == 1 ? '0' : '0',
                  transform: ("translate(" + ((item.module_size.x * _vm.proportionNumber) / 100) + "rem, " + ((item.module_size.y * _vm.proportionNumber) / 100) + "rem) rotate(" + (item.module_size.spin) + "deg)"),
                }),on:{"click":function($event){$event.stopPropagation();return _vm.moduleClick(item, _vm.module_content)}}},[_c('div',{staticClass:"item-box-default flex-center",style:(Object.assign({}, (item.module_style.module_background_switch == 1 ? {
                      background: item.module_style.module_background_color.selected != 2 ? ("url(" + (item.module_style.module_background_color.module_background_url) + ")") : '',
                      backgroundColor:  item.module_style.module_background_color.selected != 2 && item.module_style.module_background_color.module_background_url == ''  ? item.module_style.module_background_color.customize : '',
                    } : {}),
                    {borderRadius: _vm.radius[item.module_style.module_shape - 1],
                    backgroundSize: item.module_type == 17 ? 'contain' : 'cover',
                    backgroundRepeat: 'no-repeat'}))},[_c('div',{staticStyle:{"width":"100%"}},[(item.module_style.icon)?_c('div',{staticClass:"img-box flex-center position-icon",style:(_vm.icon_style(item.icon_size))},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(item.module_type != 17),expression:"item.module_type != 17"}],attrs:{"src":item.module_style.icon,"alt":""}})]):_vm._e(),(item.module_title_content.main_title_show_hidden == 1 && item.module_type != 17)?_c('span',{staticClass:"position-title",style:(_vm.title_style(item.module_title_content))},[_vm._v(" "+_vm._s(item.module_title)+" ")]):_vm._e()])])])}),0):_c('div',{staticClass:"file-model",style:({
                background: _vm.item.module_style.module_background_color.selected != 2 ? ("url(" + (_vm.item.module_style.module_background_color.module_background_url) + ")") : '',
                backgroundColor: _vm.item.module_style.module_background_color.selected != 2 && _vm.item.module_style.module_background_color.module_background_url == '' ? _vm.item.module_style.module_background_color.customize : '',
                borderRadius: _vm.radius[_vm.item.module_shape - 1],
              }),on:{"click":function($event){$event.stopPropagation();return _vm.moduleClick(_vm.item, _vm.module_content)}}},[(_vm.item.module_style.icon)?_c('div',{staticClass:"img-box flex-center"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.item.module_type != 17),expression:"item.module_type != 17"}],attrs:{"src":_vm.item.module_style.icon,"alt":""}})]):_vm._e(),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.item.module_title_content.main_title_show_hidden == 1 && _vm.item.module_type != 17),expression:"item.module_title_content.main_title_show_hidden == 1 && item.module_type != 17"}],style:({ color: _vm.item.module_title_content.main_title_color })},[_vm._v(" "+_vm._s(_vm.item.module_title)+" ")]),_c('p',{staticClass:"subtitle",style:({ color: _vm.item.subtitle_color, })},[_vm._v(" "+_vm._s(_vm.item.subtitle)+" ")])]),_c('van-dialog',{attrs:{"confirmButtonText":"是","cancelButtonText":"否","confirmButtonColor":_vm.themecolors,"show-cancel-button":""},on:{"confirm":_vm.confirm,"cancel":_vm.cancel},model:{value:(_vm.dialog_show),callback:function ($$v) {_vm.dialog_show=$$v},expression:"dialog_show"}},[_c('div',{staticClass:"dialog-title"},[_vm._v("温馨提示")]),_c('div',{staticClass:"dialog-content",domProps:{"innerHTML":_vm._s(_vm.dialog_content)}})])],1):_vm._e(),(_vm.fileLayout)?_c('Support'):_vm._e()],1):_vm._e(),(!_vm.fileLayout)?_c('Support',{staticClass:"keep_record"}):_vm._e()],1),(_vm.img)?_c('img',{staticClass:"share-img",attrs:{"src":_vm.img}}):_vm._e()])]):_vm._e(),(_vm.sideBarShow)?_c('sideBar'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }