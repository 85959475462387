<template>
  <div class="widget">
    
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.widget {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  width: 200px;
  height: 100%;
}
</style>
