// 拖动
export default{
    // 拖动
    drag(obj){
        var oL,oT,drag = document.getElementById(obj);
        var curMoveIndex = 0;
        var lastMoveIndex = 0;
        //限制最大宽高，不让滑块出去
        var maxW = document.body.clientWidth - drag.offsetWidth;
        var maxH = document.body.clientHeight - drag.offsetHeight;
        //手指触摸开始，记录div的初始位置
        drag.addEventListener('touchstart', function(e) {
            var ev = e || window.event;
            var touch = ev.targetTouches[0];
            oL = touch.clientX - drag.offsetLeft;
            oT = touch.clientY - drag.offsetTop;
        });
        //触摸中的，位置记录
        drag.addEventListener('touchmove', function(e) {
            var ev = e || window.event;
            var touch = ev.targetTouches[0];
            var oLeft = touch.clientX - oL;
            var oTop = touch.clientY - oT;
            if (oLeft < 0) {
                oLeft = 0;
            } else if (oLeft >= maxW) {
                oLeft = maxW;
            }
            if (oTop < 0) {
                oTop = 0;
            } else if (oTop >= maxH) {
                oTop = maxH;
            }
            drag.style.left = oLeft + 'px';
            drag.style.top = oTop + 'px';
            e.preventDefault(); // 阻止默认事件-ios效果最佳
            curMoveIndex++;     // 记录拖拽
        });
        //触摸结束时的处理
        drag.addEventListener('touchend', function(e) {
            if (lastMoveIndex == curMoveIndex) {
                //💥点击事件
            }else{
                var ev = e || window.event;
                var touch = ev.changedTouches[0];
                if(touch.clientX < maxW/2){
                    drag.style.left = 10 + 'px';
                }
                if(touch.clientX > maxW/2){
                    drag.style.left = maxW-10 + 'px';
                }
                e.preventDefault();
            }
            curMoveIndex = lastMoveIndex;
        });
    }
}