<template>
  <div class="demo" :style="{zIndex:active? -1:''}">
      <iframe :src="app.url" ref="iframe"></iframe>
  </div>
</template>
<style scoped lang="scss">
.demo {
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 420px;
  color: #333;
  text-shadow: none;
  justify-content: center;
  align-items: center;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    background: #fff;
  }
}
</style>
<script>
export default {
  data() {
    return {};
  },
  props: {
    app: Object,
    active: Boolean,
  },
  created() {
    // this.$nextTick( ()=> {
    //   let iframe =  this.$refs.iframe
    //   iframe.onload = function(){
    //       var drag = document.getElementById("drag")
    //       console.log(drag);
    //       drag.style="display:none"
    //   };
    // })
  },
};
</script>