<template>
    <!-- 嘉宾库 -->
    <div class="attend">
        <van-search class="search" shape="round" v-model="keywords" placeholder="搜索" @input="retrieval()" />
        <van-index-bar id="scroll" :index-list="Object.keys(retrievallist)" ref="indexbar"
            :highlight-color="themecolors" :sticky-offset-top='0'>
            <van-loading :color="themecolors" :text-color="themecolors" v-if="Loading" />
            <van-empty :image="require('@/assets/successful.svg')" v-else-if="Object.keys(retrievallist).length === 0">
                暂无相关人员
            </van-empty>
            <div v-for="(value,key1) in retrievallist" :key="key1">
                <van-index-anchor :index="key1" class="active_acnchor" />
                <div class="user flex-center" v-for="(i,j) in value" :key="j" @click.stop="attendInfo(i, key1)">
                    <div class="avatar flex-center">
                        <img :src="i.avatar_url" alt="">
                    </div>
                    <div class="user-info">
                        <p class="hospital">{{i.name}}</p>
                        <span class="hospital">{{i.hospital}}</span>
                    </div>
                </div>
            </div>
        </van-index-bar>
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    props: {
        app: Object
    },
    data() {
        return {
            Loading: false,
            keywords: "",
            params: {},
            list: [],
            indexList: ['*', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
            retrievallist: [],// 检索列表
        }
    },
    created() {
        if (this.schedulevalue) {
            this.keywords = this.schedulevalue
        }
        let obj = {
            module_id: this.app.random_number,
            page_id: this.app.page_id,
            site_id: this.site_id,
        }
        this.params = obj;
        this.get_list_expert_contact_list();
    },
    computed: {
        ...mapState(['schedulevalue', 'themecolors', 'site_id'])
    },
    methods: {
        // 前端检索功能
        retrieval() {
            this.retrievallist = {}
            this.indexList.forEach(element => {
                if (this.list[element]) {
                    this.list[element].forEach(item => {
                        if (item.name.toLowerCase().indexOf(this.keywords.toLowerCase()) != -1) {
                            if (this.retrievallist[element]) {
                                this.retrievallist[element].push(item)
                            } else {
                                this.retrievallist[element] = [item]
                            }
                        }
                    })
                }

            })
        },
        attendInfo(i, key) {
            let obj = {
                component: "AttendIntro",
                hide: false,
                height: 700,
                width: 420,
                key: 'demo_attendIntro',
                module_title: "嘉宾简介",
                reload:true,
                pid: new Date().valueOf() + "." + parseInt(Math.random() * 99999999),
                data: {
                    ...this.params,
                }
            }
            this.$store.commit("openApp", obj);
            this.$cookies.set('people_id', i.id)
            window.sessionStorage.setItem('listkey', key)
            window.sessionStorage.setItem("attendIntro", JSON.stringify(i))
        },
        // 获取参会者
        get_list_expert_contact_list() {
            this.Loading = true;
            this.$store.dispatch("get_list_expert_contact_list", {
                site_id: this.params.site_id,
                keywords: this.keywords,
                module_id: this.params.module_id,
            }).then(res => {
                this.Loading = false;
                this.$store.commit('changeschedulevalue', '')
                if (res.data.code == 200) {
                    this.list = res.data.data.module_content;
                    this.indexList.forEach(element => {
                        if (this.list[element]) {
                            this.list[element].sort((a, b) => a.name.localeCompare(b.name, 'zh'))
                        }
                    })
                    this.retrievallist = this.list
                    let key = window.sessionStorage.getItem('listkey')
                    if (key) {
                        this.$nextTick(() => {
                            this.$refs.indexbar.scrollTo(key)
                            window.sessionStorage.setItem('listkey', '')
                        })
                    }
                    this.retrieval()
                }
            }).catch(err => {
                // console.log(err)
                this.$store.commit('changeschedulevalue', '')
            })
        }
    }
}
</script>

<style lang='less' scoped>
.active_acnchor {

    /deep/.van-index-anchor--sticky,
    /deep/.van-index-bar__index--active {
        top:-95px;
    }
}

/deep/.van-empty,
/deep/.van-loading {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -30%);
}

/deep/.van-empty {
    width: 100%;

    .van-empty__image {
        width: 300px;
        height: 300px;
    }

    .van-empty__bottom {
        font-size: 14px;
        color: #999;
    }
}

/deep/.van-loading {
    top: 50%;
}

.attend {
    background: #fff;
    max-width: 420px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    // overflow-y: scroll;
    .flex-center {
        display: flex;
        align-items: center;
    }

    .search {
        position: relative;
        width: 100%;
        height: 54px;
        z-index: 9999999;
    }

    .user {
        border-bottom: 1px solid #F7F8FA;
        margin: 10px 20px 0;
        padding-bottom: 10px;
    }

    .avatar {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;

        img {
            width: 100%;
            height: auto;
        }
    }

    .user-info {
        flex: 1;

        p {
            font-size: 14px;
        }

        span {
            display: block;
            font-size: 12px;
            color: #969799;
        }

        .hospital {
            max-width: 250px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    /deep/.van-index-bar {
        height: calc(100% - 54px);
        overflow-y: scroll;
        position: relative;
        .van-index-bar__index {
            line-height: 18px;
        }
    }
    /deep/.van-index-anchor {
        background: #F7F8FA;
    }

    /deep/.van-index-anchor--sticky,
    /deep/.van-index-bar__index--active {
        color: #2c3e50;
        left: 0px !important;

        // transform: translate3d(0px, 300px, 0px)!important;
    }

    // /deep/.van-index-bar__index--active{
    //     color: #2c3e50!important;
    // }
}
</style>