import state from "./state";

export default {
    //token
    changelogintoken(state, v) {
        state.token = v;
    },
    //个人信息
    changeuserinfrom(state, v) {
        state.userinfrom = v;
    },
    // 日程搜索关键字
    changeschedulevalue(state, v) {
        state.schedulevalue = v
    },
    // 主题色的修改
    changethemecolor(state, v) {
        state.themecolor = v
    },
    // 无透明度主题色的修改
    changethemecolors(state, v) {
        state.themecolors = v
    },
    // 技术支持颜色自定义
    changetechSupporTcolors(state, v) {
        state.techSupporTcolors = v
    },
    //修改头部状态
    changeUserHeader(state, v) {
        state.user_header = v
    },
    //修改音乐显示
    changeMusicSwitch(state, v) {
        state.music = v
    },
    //站点关闭到期关闭音乐与头部显示
    changeHeaderMusic(state, v) {
        state.showStation = v
    },
    //改变状态码
    changeErrCode(state, v) {
        state.err_code = v
    },

    //主页让头部显示
    changeIsShowHead(state, v) {
        state.isShowHead = v
    },

    //修改音乐图片
    changeMusicImg(state, v) {
        state.boolplay = v
    },

    //日程块显示日程与详细
    changge_show_schedule(state, v) {
        state.show_schedule = v
    },
    // 云展厅内页面展示
    changge_show_coopimg(state, v) {
        state.show_coopimg = v
    },
    // pc是否显示登录注册按钮
    changge_loginStatus(state, v) {
        state.loginStatus = v
    },
    // pc用户是否需要注册
    changge_userlogin(state, v) {
        state.userlogin = v
    },
    // 获取site_id
    change_site_id(state,v){
        state.site_id=v
    },
    /**
     * @description: 退出登录
     */
    logout(state) {
        state.nowApp = false;
        state.openAppList = [];
        state.showLogin = true;
    },
    /**
     * @description: 登录
     */
    login(state) {
        state.showLogin = false;
    },
    /**
     * @description: 打开上一次的应用
     */
    openTheLastApp(state) {
        for (let i = state.openAppList.length - 1; i >= 0; i--) {
            if (!state.openAppList[i].hide) {
                this.commit("showApp", state.openAppList[i]);
                break;
            }
        }
    },
    /**
     * @description: 最小化应用
     */
    hideApp(state, app) {
        for (let i in state.openAppList) {
            if (state.openAppList[i].pid == app.pid) {
                state.openAppList[i].hide = true;
                break;
            }
        }
        this.commit("openTheLastApp");
    },
    /**
     * @description: 根据PID关闭应用
     */
    closeWithPid(state, pid) {
        for (let i in state.openAppList) {
            if (state.openAppList[i].pid == pid) {
                state.openAppList.splice(i, 1);
                break;
            }
        }
        for (let i in state.dockAppList) {
            if (
                state.dockAppList[i].pid == pid &&
                !state.dockAppList[i].keepInDock
            ) {
                state.dockAppList.splice(i, 1);
                break;
            }
        }
    },
    /**
     * @description: 关闭应用
     */
    closeApp(state, app) {
        if (app.hideWhenClose) {
            this.commit("hideApp", app);
        } else {
            for (let i in state.openAppList) {
                if (app.pid) {
                    if (state.openAppList[i].pid == app.pid) {
                        state.openAppList.splice(i, 1);
                        break;
                    }
                } else {
                    if (state.openAppList[i].key == app.key) {
                        state.openAppList.splice(i, 1);
                        break;
                    }
                }
            }
            if (!app.keepInDock) {
                for (let i in state.dockAppList) {
                    if (app.pid) {
                        if (state.dockAppList[i].pid == app.pid) {
                            state.dockAppList.splice(i, 1);
                            break;
                        }
                    } else {
                        if (state.dockAppList[i].key == app.key) {
                            state.dockAppList.splice(i, 1);
                            break;
                        }
                    }
                }
            }
            this.commit("openTheLastApp");
        }
    },
    /**
     * @description: 打开应用
     */
    openApp(state, app) {
        if (state.launchpad) {
            state.launchpad = false;
        }
        if (app.outLink) {
            app.url && window.open(app.url);
            return;
        }

        // 重载数据
        if(app.reload){
            for (let i in state.openAppList) {
                if (state.openAppList[i].key == app.key) {
                    state.openAppList.splice(i,1)
                    break;
                }
            }
        }
        let isExist = false;
        for (let i in state.openAppList) {
            if (state.openAppList[i].key == app.key) {
                if (state.openAppList[i].hide) {
                    state.openAppList[i].hide = false
                }
                isExist = true;
                break;
            }
        }
        if (isExist) {
            this.commit("showApp", app);
        } else {
            app.pid =app.pid? app.pid:app.id;
            app = JSON.parse(JSON.stringify(app));
            app.hide = false;
            state.openAppList.push(app);
            state.openAppListIgndex = state.openAppList.length - 1
            state.dockAppList.forEach((item,index)=>{
                if(item.pid==app.pid){
                    state.dockAppList.unshift(state.dockAppList.splice(index,1)[0])
                }
            })
        }
        state.nowApp = JSON.parse(JSON.stringify(app));
    },
    /**
     * @description: 显示并置顶APP
     */
    showApp(state, app) {
        let openAppList = JSON.parse(JSON.stringify(state.openAppList));
        for (let i in openAppList) {
            if (openAppList[i].pid == app.pid) {
                state.openAppListIgndex = i
                break;
            }
        }
    },
    /**
     * @description: 根据key打开APP
     */
    openAppByKey(state, key) {
        let app = tool.getAppByKey(key);
        if (app) {
            this.commit("openApp", app);
        }
    },
    /**
     * @description: 带参数打开App
     */
    openWithData(state, data) {
        data.app.data = data.data;
        this.commit("openApp", data.app);
    },
    /**
     * @description: 获取常驻Dock的App列表
     */
    getDockAppList(state, v) {
        let arr = [];
        let appList = v;
        for (let app of appList) {
            if (app.keepInDock) {
                app.pid = app.id
                arr.push(app);
            }
        }
        state.dockAppList = arr;
    },
    openMenu(state, key) {
        switch (key) {
            case "close":
                this.commit("closeApp", state.nowApp);
                break;
            default:
                break;
        }
    },
    launchpad(state) {
        state.launchpad = !state.launchpad;
    },
    Registered(state,app){
        state.userlogin=true
        console.log(state.userlogin);
        for (let i in state.openAppList) {
            if (state.openAppList[i].constraint) {
                state.openAppList[i].constraint = false;
            }
        } 
        state.dockAppList.forEach(element => {
            if (element.constraint) {
                element.constraint = false;
            }
            if(element.inquiry){
                element.inquiry=false
            }
        });
    },
    affirmShow(state,app){
        for (let i in state.openAppList) {
            if (state.openAppList[i].pid==app.pid) {
                state.openAppList[i].constraint = true;
                state.openAppList[i].inquiry=false
            }
        } 
    },
    cancelShow(state,app){
        for (let i in state.openAppList) {
            if (state.openAppList[i].pid==app.pid) {
                state.openAppList[i].constraint = false;
                state.openAppList[i].inquiry=false
            }
        } 
    }
}