<template>
  <div class="mac-os" @mousedown.self="boot" @contextmenu.prevent="onContextShow()">
    <transition name="fade">
      <Bg v-if="isBg" :bgkv="bgkv"></Bg>
    </transition>
    <transition name="fade">
      <Loading v-if="isLoading" @loaded="loaded" :bgkv="bgkv"></Loading>
    </transition>
    <!--  @shutdown="shutdown"@lockScreen="lockScreen"@logout="logout" -->
    <transition name="fade">
      <DeskTop v-if="isDeskTop" @launchpad="launchpad" :moduleContent='moduleContent'></DeskTop>
    </transition>
    <transition name="fade">
      <el-empty :image="require('@/assets/closed.svg')" :image-size="300" description=" "
        style="height: calc(100vh - 60px);" v-if="uncontent == 3001" />
      <el-empty :image="require('@/assets/deled.svg')" :image-size="300" description=" "
        style="height: calc(100vh - 60px);" v-if="uncontent == 3002" />
      <el-empty :image="require('@/assets/not_started.svg')" :image-size="300" description=" "
        style="height: calc(100vh - 60px);" v-if="uncontent == 3003" />
      <el-empty :image="require('@/assets/finished.svg')" :image-size="300" description=" "
        style="height: calc(100vh - 60px);" v-if="uncontent == 3004" />
    </transition>
    <transition name="fade">
      <LaunchPad v-if="isLaunchPad" :moduleContent='moduleContent'></LaunchPad>
    </transition>
  </div>
</template>
<style>
@import "../../assets/css/app.css";
@import "../../assets/css/animation.css";
</style>
<style scoped lang="scss">
.mac-os {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
</style>
<script>

import Bg from "@/components/mac/Bg.vue";
import LaunchPad from "@/components/mac/LaunchPad.vue";
import Loading from "@/components/mac/Loading.vue";
import DeskTop from "@/components/mac/DeskTop.vue";
export default {
  data() {
    return {
      isBg: true,
      isLoading: false,
      isDeskTop: false,
      isLaunchPad: false,
      uncontent: '',
      moduleContent: [],
      bgkv: [],
    };
  },
  components: {
    Bg,
    LaunchPad,
    Loading,
    DeskTop
  },

  created() {
    this.check_registered_the_forms()
    this.homeShow();
  },
  methods: {
    /**
     * @description: 检测用户是否注册成功
     */
    check_registered_the_forms() {
      this.$store
        .dispatch("check_registered_the_forms", {
          site_url: sessionStorage.getItem('siteUrl'),
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.$store.commit("changge_userlogin", true);
          }
          if (res.data.code == 100) {
            this.$store.commit("changge_userlogin", false);
          }
        });
    },
    /**
     * @description: 获取模块数据
     */
    homeShow() {
      this.$store.dispatch("front_desk_home_show", { site_url: sessionStorage.getItem('siteUrl'), })
        .then((res) => {
          this.uncontent=res.data.code
          if (res.data.code == 200) {
            this.boot();
            this.$store.commit("changethemecolors", res.data.data.page_style.theme_color);
            this.$store.commit("change_site_id", res.data.data.site_id);
            this.$cookies.set("supportInformation", res.data.data.page_style.hide_show.support_information);
            this.bgkv = res.data.data.page_style.carousel.carousel_link
            res.data.data.module_content.forEach((element) => {
              element.width = 420;
              element.height = 700;
              element.keepInDock = true;
              element.hideInDesktop = true
              element.key = element.id + "module";
              element.component = "DemoWeb";
              if (element.configure.module_sign_up_form_login && !this.$store.state.userlogin) {
                if (element.configure.compulsory) {
                  // 强制表单
                  element.constraint = true
                } else {
                  // 询问弹窗
                  element.constraint = false
                  element.inquiry = true
                }
              } else {
                element.constraint = false
              }
              if (element.module_type == 1 || (element.module_type == 2 && element.configure.module_value.type == 2)) {
                element.component = "Blank";
                window.sessionStorage.setItem("module_value", JSON.stringify(element.configure.module_value));
              }
              if (element.module_type == 2) {
                // 外链
                if (element.configure.module_value.type == 1) {
                  element.outLink = true;
                  element.url = element.configure.module_value.off_site_links
                }
              }
              if (element.module_type == 3 && element.configure.module_value.website_page != "") {
                element.outLink = true;
                element.url = element.configure.module_value.website_page;
              }
              if (element.module_type == 4) {
                element.component = "Articlelist";
              }
              if (element.module_type == 5) {
                element.component = "Cooperation";
              }
              if (element.module_type == 6) {
                element.component = "PhotoGallery";
              }
              if (element.module_type == 7) {
                element.component = "Video";
              }
              if (element.module_type == 8) {
                element.component = "FilEdownload";

              }
              if (element.module_type == 9) {
                element.width = 600;
                element.height = 700;
                element.component = "Schedule";
              }
              if (element.module_type == 10) {
                element.component = "Exhibition";
              }
              if (element.module_type == 11) {
                element.component = "Attend";
                element.disableResize = true
              }
              if (element.module_type == 12) {
                element.component = "LiveList";
              }
              if (element.module_type == 13) {
                element.constraint = false
                element.inquiry = false
                element.component = "Register";
              }
              if (element.module_type == 14) {
                element.component = "RegPayment";
              }
              if (element.module_type == 15) {
                element.component = "Hotel";
              }
              if (element.module_type == 16) {
                element.component = "Antiepidemic";
              }
              if (element.module_type == 18) {
                element.component = "Addendum";
              }
              if (element.module_type == 19) {
                element.component = "ConferenceAddress";
              }
            });
            this.moduleContent = res.data.data.module_content;
          }
        });
    },
    boot() {
      this.isLoading = true;
    },
    onContextShow() {
      console.log("onContextShow");
    },
    loaded() {
      this.isLoading = false;
      this.isBg = true;
      this.isDeskTop = true;
    },
    launchpad(show) {
      this.isLaunchPad = show;
    }
  },
};

// const logined = () => {
//   isLogin.value = false;
//   isDeskTop.value = true;
// }
// const lockScreen = () => {
//   isLogin.value = true;
// }
// const logout = () => {
//   localStorage.removeItem("user_name");
//   isDeskTop.value = false;
//   isLogin.value = true;
// }
// const shutdown = () => {
//   localStorage.removeItem("user_name");
//   isDeskTop.value = false;
//   isLogin.value = false;
//   isLoading.value = false;
//   isBg.value = false;
// }
// return { moduleContent };
</script>
