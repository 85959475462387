import Player from 'xgplayer'
// import PlayIcon from '../assets/play.svg'
import PauseIcon from '../assets/pause.svg'
import '../style/controls/play.scss'

let s_play = function () {
  let player = this
  let util = Player.util
  let playBtn = player.config.playBtn ? player.config.playBtn : {}
  let btn
  if (playBtn.type === 'img') {
    btn = util.createImgBtn('play', playBtn.url.play, playBtn.width, playBtn.height)
  } else {
    btn = util.createDom('xg-play', `<xg-icon class="xgplayer-icon">
                                      <div class="xgplayer-icon-play"><svg class="icon" aria-hidden="true">
                                        <use xlink:href="#icon-xgstartPlay"></use>
                                      </svg>
                                      </div>
                                      <div class="xgplayer-icon-pause">
                                      <svg class="icon" aria-hidden="true">
                                         <use xlink:href="#icon-xgstartPause"></use>
                                      </svg>
                                      </div>
                                     </xg-icon>`, {}, 'xgplayer-play')
  }

  let livestatus
  livestatus = util.createDom('xg-play', `<xg-icon class="xgplayer-icon">
  <div id="xgplayer-icon-livestatus">
  <img id="xgplayer-icon-livestatus" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyNC4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0i5Zu+5bGCXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOTYgMzYuOCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgOTYgMzYuODsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0ZGMzMzMzt9DQoJLnN0MXtmb250LWZhbWlseTonUGluZ0ZhbmctU0MtUmVndWxhcic7fQ0KCS5zdDJ7Zm9udC1zaXplOjMycHg7fQ0KPC9zdHlsZT4NCjxnIGlkPSLlm77lsYJfMl8xXyI+DQoJPGcgaWQ9IuWbvuWxgl8xLTIiPg0KCQk8dGV4dCB0cmFuc2Zvcm09Im1hdHJpeCgxIDAgMCAxIDAgMjcuNTIpIiBjbGFzcz0ic3QwIHN0MSBzdDIiPuebtOaSreS4rTwvdGV4dD4NCgk8L2c+DQo8L2c+DQo8L3N2Zz4NCg=="/>
  </div>
  </xg-icon>`, {}, 'xgplayer-fullscreen')

  let tipsText = {}
  tipsText.play = player.lang.PLAY_TIPS
  tipsText.pause = player.lang.PAUSE_TIPS
  let tips = util.createDom('xg-tips', `<span class="xgplayer-tip-play">${tipsText.play}</span>
                                        <span class="xgplayer-tip-pause">${tipsText.pause}</span>`, {}, 'xgplayer-tips')
  btn.appendChild(tips)
  player.once('ready', () => {
    if(player.controls) {
      player.controls.appendChild(btn)
      // player.controls.appendChild(livestatus)
    }
  });

  ['click', 'touchend'].forEach(item => {
    btn.addEventListener(item, function (e) {
      e.preventDefault()
      e.stopPropagation()
      player.emit('playBtnClick')
    })
  })
}

export default {
  name: 's_play',
  method: s_play
}