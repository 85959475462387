<template>
    <div class="suspend-btn" v-show="showSideBar" id="drag">
        <!-- <div style="height: 1.26rem;"> -->
        <div class="home-box" :style="{ background: themecolor }" @click="ExportChange" v-if="pathName == 'attendIntro'">
            导出
        </div>
        <div class="back-top" :style="{background:themecolor}" @click.stop="backTop" v-if="flag_scroll">
            <img src="@/assets/TuPian/backtop.svg" alt="">
        </div>
        <div class="home-box" :style="{background:themecolor}"  @click.stop="goback">
            <img src="@/assets/icon/go_back.svg" alt="" style="width:.18rem;">
            <!-- <p>返回</p> -->
        </div>
        <div class="home-box" :style="{background:themecolor}" v-if="articleDetails" @click="gohome">
            <img src="@/assets/icon/home.svg" alt="">
            <!-- <p>首页</p> -->
        </div>
        <!-- <div class="home-box" @click="share" v-if="pathName == 'Home'">
            <img src="@/assets/TuPian/share.svg" alt="">
            <p>分享</p>
        </div> -->
        <!-- </div> -->
    </div>
</template>
<script>
import { mapState } from "vuex";
import drag from "@/js/drag.js";
export default {
    data(){
        return{
            timeOutEvent:0,
            flag_scroll:false,
            pathName:"",
            showSideBar: false,
            articleDetails: true
        }
    },
    // props: {
    //     flushbonading: {
    //         type: Boolean,
    //         default: false
    //     }
    // },
    created(){
        if(this.$route.query.singePage != 'singePage') {
            this.showSideBar = true
        }
        if((this.$route.query.singePage == 'singePage') && this.$route.query.backtrack){
            this.showSideBar = true
            this.articleDetails = false
        }
        this.pathName = this.$route.name;
    },
    mounted(){
        drag.drag('drag')
        window.addEventListener('scroll', this.handleScroll, true);
    //     this.$nextTick(()=>{
    //      document.getElementById('scroll').addEventListener('touchmove' , function(e){
    //         e.preventDefault();
    //      },{ passive: true })
    // })
    },
    computed: {
        ...mapState(['themecolor','show_schedule','homeurl','show_coopimg']),
        showSidebar(){
            if(window.self === window.top){
                return true
            }else{
                return false
            }
        }
    },
    methods:{
        share(){
            this.$emit("sharelick",1)
        },
        handleScroll(e){
            let scrollTop = document.getElementById('scroll')?.scrollTop;          
            if(scrollTop > 100){
                this.flag_scroll = true
            }else {
                this.flag_scroll = false
            }
        },
        backTop(){
            // document.documentElement.scrollTop = 0;
            let scrollTop = document.getElementById('scroll').scrollTop;
            // document.getElementById('scroll').scrollTop = 0;
            let timer = setInterval(()=>{
                if(scrollTop <= 0){
                    document.getElementById('scroll').scrollTop = 0;
                    clearInterval(timer);
                    return
                }
                scrollTop -= 10
                document.getElementById('scroll').scrollTop = scrollTop;
            },0)
            this.flag_scroll = false;
        },
        goback(){
            if(this.$route.path=='/schedule' && !this.show_schedule){
                this.$store.commit('changge_show_schedule', true)
            } else if(this.$route.path=='/exhibition' && this.show_coopimg){
                sessionStorage.removeItem('logolink')
                sessionStorage.removeItem('pictureTitle')
                this.$store.commit('changge_show_coopimg', false)
            }else{
                this.$router.go(-1)
            }
        },
        gohome(){
            if(this.pathName!="Home"){
                this.$router.replace(`/event/${window.sessionStorage.getItem("siteUrl")}`)
            }else{
                location.href=`${this.homeurl}/event/${window.sessionStorage.getItem("homeUrl")}`
            }
            this.$store.commit('changge_show_schedule', true)
            
            if(this.show_coopimg){
                this.$store.commit('changge_show_coopimg', false)
                sessionStorage.removeItem('logolink')
                sessionStorage.removeItem('pictureTitle')
            }
            
        },
        ExportChange() {
            this.$store.dispatch('export_guest_academic', {
                page_id: this.$cookies.get('site_id'),
                people_id: this.$cookies.get('people_id')
            }).then(res => {
                if (res.data.code) {
                    this.$toast(res.data.message)
                } else {
                    let elink = document.createElement('a');
                    elink.href = this.homeurl + '/api/v1/micro/schedule/export_guest_academic?page_id=' + this.$cookies.get('site_id') + '&people_id=' + this.$cookies.get('people_id');
                    elink.target = "_parent";
                    document.body.appendChild(elink);
                    elink.click();
                    document.body.removeChild(elink);
                }

            })
        },
    }
}
</script>

<style  lang='less' scoped>
.suspend-btn{
    position: fixed;
    bottom: 18%;
    right: 3%;
    text-align: center;
    width: .55rem;
    // height: 1.45rem;
    padding: .1rem;
    z-index: 999999;
    min-height: 110px;
    max-height: 1.5rem;
    .back-top,.home-box{
        width: .35rem;
        height: .35rem;
        background: rgba(0, 225, 175, .8);
        border-radius: 50%;
    }
    .back-top{
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: .22rem;
            -webkit-touch-callout:none;
            -webkit-user-select:none;
            -khtml-user-select:none;
            -moz-user-select:none;
            -ms-user-select:none;
            user-select:none;
        }
    }
    .home-box{
        margin-top: .1rem;
        padding-top: .07rem;
        // line-height: .12rem;
        font-size: .12rem;
        color: #fff;
        img{
            width: .18rem;
            -webkit-touch-callout:none;
            -webkit-user-select:none;
            -khtml-user-select:none;
            -moz-user-select:none;
            -ms-user-select:none;
            user-select:none;
        }
    }
}
</style>