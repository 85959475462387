<template>
  <div class="support-box">
    <p
      class="support"
      :style="{color: themecolors}"
      v-if="support_information == 1"
      @click="skip"
    >
      技术支持：{{ technical_support }}
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      technical_support: "",
      support_information: 0,
    };
  },
  computed: {
    ...mapState(["themecolors","techSupporTcolors"]),
  },
  created() {
    if (sessionStorage.getItem("technical_support")) {
      this.technical_support = sessionStorage.getItem("technical_support");
    } else {
      this.technical_support = "轻视云";
    }
  },
  mounted() {
    this.support_information = this.$cookies.get("supportInformation");
  },
  methods: {
    skip() {
      window.location.href = "https://www.qingtv.com/";
    },
  },
};
</script>

<style>
/* .support-box{
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 50%;
    height: 36px;
    transform: translateX(-50%);
} */
.support {
  margin: 0.1rem 0 0.1rem;
  text-align: center;
  font-size: 0.12rem;
  color: #999;
}
</style>
