<template>
    <!-- 注册表单 -->
    <div class="loginBox-page">
        <div class="loginBox" id="scroll" :style="{ '--themecolor': themecolors }">
            <van-loading :color="themecolors" :text-color="themecolors" v-if="Loading" />
            <div class="login-main-box" v-else>
                <div class="top">
                    <img :src="form_info.head_img" alt="">
                </div>
                <div class="main">
                    <ul>
                        <li v-for="(item, index) in tabsName" :key="index" v-show="false">
                            <span class="tab-link" @click.stop="tabsSwitch(index)" :class="{ active: item.isActive }">{{
                            item.name }}</span>
                            <img class="tab-img" :class="{ active: item.isActive }" src="@/assets/otherTU/tabs.svg">
                        </li>
                    </ul>
                    <div class="tabsContent">
                        <!-- 上方隐藏 -->
                        <div class="registerBox">
                            <div class="successful" v-if="form_info.form.length == 0">
                                <img src="@/assets/form_null.svg">
                            </div>
                            <div class="successful" v-else-if="succeed">
                                <div class="modify_register" @click="succeed = false">修改注册</div>
                                <img src="@/assets/successful.svg">
                                <p>{{ successText }}</p>
                            </div>
                            <div class="registerCon" v-else>
                                <van-form>
                                    <div v-for="(item, index) in form_info.form" :key="index">
                                        <van-field v-if="
                                    item.option.length == 0 && item.select2 != 3 && item.select2 != 4 && item.select2 != 10 ? item.select2 == 1 || item.select2 == 5 || item.select2 == 6 || item.select2 == 9 || item.select == '文本' || item.select == 1
                                        ? true : false : false" v-model="item.value" placeholder="请输入内容"
                                            :label="`${(index + 1) + '. ' + item.title}`" :required="item.require"
                                            :rules="[{ required: item.require }]"
                                            :maxlength="item.select2 == 1 ? 10 : 60" />
                                        <!-- 单选 -->
                                        <van-field
                                            v-if="item.select2 == 2 || (item.select == 2 && item.queRadio == 1 && item.select2 != 8)"
                                            name="radio" :label="`${(index + 1) + '. ' + item.title}`"
                                            :required="item.require" :placeholder="item.title"
                                            :rules="[{ required: item.require }]">
                                            <template #input>
                                                <van-radio-group v-model="item.value">
                                                    <van-radio v-for="(i, j) in item.option"
                                                        :checked-color="themecolors" icon-size="14px" :key="j"
                                                        :name="i.title">{{ i.title }}
                                                    </van-radio>
                                                </van-radio-group>
                                            </template>
                                        </van-field>

                                        <!-- 多选 -->
                                        <van-field
                                            v-if="(item.select2 != 2 && item.select2 != 8) && (item.select == 2 && item.queRadio == 2)"
                                            name="checkboxGroup" :label="`${(index + 1) + '. ' + item.title}`"
                                            :required="item.require" :placeholder="item.title"
                                            :rules="[{ required: item.require }]">
                                            <template #input>
                                                <van-checkbox-group v-model="item.value">
                                                    <van-checkbox shape="square" v-for="(i, j) in item.option"
                                                        :checked-color="themecolors" icon-size="14px" :key="j"
                                                        :name="i.title">{{ i.title }}</van-checkbox>
                                                </van-checkbox-group>
                                            </template>
                                        </van-field>
                                        <!-- 上传图片 -->
                                        <van-field v-if="item.select2 == 10" name="uploader" :required="item.require"
                                            :label="`${(index + 1) + '. ' + item.title}`"
                                            :rules="[{ required: item.require }]">
                                            <template #input>
                                                <van-uploader v-model="item.value" :after-read="afterRead"
                                                    :preview-full-image="false" :max-count="1"
                                                    :max-size="10 * 1024 * 1024" @oversize="onOversize"
                                                    accept=".jpeg,.jpg,.png,.gif" />
                                            </template>
                                        </van-field>

                                        <!-- 手机 -->
                                        <van-cell class="iphone" v-if="item.select2 == 4">
                                            <van-field v-if="item.select2 == 4" v-model="item.value"
                                                :required="item.require" :label="`${(index + 1) + '. ' + item.title}`"
                                                placeholder="请输入内容"
                                                :rules="[{ required: item.require, pattern: /^1[3456789]\d{9}$/, }]" />
                                            <div class="iphone-code" v-if="item.select2 == 4 && item.isPhoneValue">
                                                <!-- 手机验证码输入 -->
                                                <van-field v-if="item.select2 == 4 && item.isPhoneValue"
                                                    v-model="inputCode" maxlength="6"
                                                    @input="inputCode = inputCode.replace(/[^\d.]/g, '')"
                                                    placeholder="请输入验证码" />
                                                <van-button class="code" v-if="item.select2 == 4 && item.isPhoneValue"
                                                    round block type="info" :disabled='codeDisabled' @click="getCode">
                                                    {{ codeText }}</van-button>
                                            </div>
                                        </van-cell>

                                        <!-- 隐私 -->
                                        <van-checkbox v-if="item.select2 == 8" v-model="item.value" shape="square"
                                            :title="item.title" :checked-color="themecolors">
                                            <p @click.stop="privacyClick(item)" style="font-size:12px;">{{ item.title }}
                                            </p>
                                        </van-checkbox>
                                        <van-field v-if="item.select2 == 3" readonly clickable name="area"
                                            :value="item.value" :required="item.require" placeholder="请输入内容"
                                            :label="`${(index + 1) + '. ' + item.title}`"
                                            :rules="[{ required: item.require }]"
                                            @click="current = item, showArea = true" />
                                    </div>
                                    <div class="regisbtn submit-btn" style="margin: 16px;">
                                        <van-button round block type="info" native-type="submit" :disabled='isSave'
                                            @click="add_front_desk_registration_form">{{ formSow ? "修改" : "提交" }}
                                        </van-button>
                                    </div>
                                </van-form>
                            </div>
                        </div>
                        <van-popup v-model="showArea" position="bottom">
                            <van-area :area-list="info.areaList" @confirm="onConfirm" :columns-num="2"
                                @cancel="showArea = false" />
                        </van-popup>
                        <van-dialog v-model="showPrivacy" @confirm="current.value = true" confirmButtonText="同意"
                            :confirmButtonColor="themecolors" show-cancel-button>
                            <!-- <div class="dialog-title">隐私协议</div> -->
                            <div class="dialog-content" v-html="privacy"></div>
                        </van-dialog>
                    </div>
                </div>
            </div>
            <div v-if="privacy_agreement_img_url" class="cover_img">
                <div class="imgs">
                    <img :src="privacy_agreement_img_url">
                </div>
                <div class="detail_btn" @click="godetail">跳过</div>
            </div>
        </div>
    </div>
</template>
<script>
import { areaList } from '@vant/area-data';
import { mapState } from "vuex"; //引入vuex
import * as qiniu from 'qiniu-js'
export default {
    data() {
        return {
            formSow: false,//是否注册成功
            formid: null,// 修改注册列表id
            site_url: "",
            idList: [{ text: "1", value: 0 }, '2'],
            info: {
                area: "",        // 地区
                showArea: false,
                areaList: areaList, // 数据格式见 Area 组件文档
            },
            isActive: true,
            Issing: true,
            privacy_agreement_img_url: '',
            tabsName: [
                {
                    name: "登录",
                    value: 0,
                    isActive: true,
                },
                {
                    name: "注册",
                    value: 1
                },
            ],
            params: {},
            Loading: true,
            form_info: {},
            privacy: "",         // 隐私协议
            showPrivacy: false,  // 隐私协议弹窗
            selectNull: false,   // 下拉未填写状态
            showArea: false,     // 地区弹窗
            current: {},
            isSave: false,
            succeed: false,
            overall: 0,          // 是否是全局表单
            // checked: false,
            codeText: '获取验证码',
            codeTime: 61,
            codeDisabled: false,
            inputCode: '',
            pastCode: false,
            initialForm: [],  //提交前存储数据
            qiniutoken: "",//七牛token
            successText: "注册成功！"
        }
    },
    watch: {
        userlogin(newval, oldval) {
            if (newval) {
                this.succeed = true;
                this.formSow = true;
                this.form_query()
            }
        }
    },
    created() {
        this.site_url = window.sessionStorage.getItem('siteUrl');
        this.GetToken()
        if (!this.userlogin) {
            this.succeed = false;
            this.form_details()
        } else {
            this.succeed = true;
            this.formSow = true;
            this.form_query()
        }
    },
    computed: {
        ...mapState(["userlogin", "themecolors", "site_id"])
    },
    methods: {
        godetail() {
            this.privacy_agreement_img_url = ''
        },
        // 七牛token
        GetToken() {
            this.$store.dispatch('get_verify_space_size', {
                site_id: this.site_id,
                storage_space: '1',
                size: '86094'
            }).then(res => {
                if (res.data.code = 200) {
                    this.qiniutoken = res.data.data.upload_token
                }
            })
        },
        onOversize(file) {
            this.$toast("图片大小不能超过10MB")
        },
        // 图片上传到服务器
        afterRead(file, detail) {
            this.isSave = true;
            file.status = 'uploading';
            let uptoken = this.qiniutoken
            let key = new Date().getTime() + Math.random(1000) + file.file.name;   //这是上船后返回的文件名，这里为了避免重复，加上了时间戳和随机数
            var config = {
                useCdnDomain: true,        //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
                domain: "http://images.qingtv.com", //配置好的七牛云域名  如   https://cdn.qniyun.com/
                chunkSize: 100,     //每个分片的大小，单位mb，默认值3
                forceDirect: true    //直传还是断点续传方式，true为直传
            };
            var putExtra = {
                fname: file.file.name,    //文件原始文件名
                params: {},
                mimeType: [] || null
                // ...config,
            };
            var observable = qiniu.upload(file.file, key, uptoken, putExtra, config);
            observable.subscribe({
                next: (result) => {
                    file.message = `上传中${Math.trunc(result.total.percent)}%`;
                },
                error: (err) => {
                    //上传错误后触发
                    console.log(err);
                    // reject(err)
                },
                complete: (result) => {
                    //上传成功后触发。包含文件地址。
                    this.isSave = false;
                    file.status = '';
                    file.message = '';
                    this.form_info.form.forEach(item => {
                        if (item.select2 == 10) {
                            item.value[detail.index] = { url: "https://images.qingtv.com/" + result.key + "" }
                        }
                    })
                    resolve(result)
                },
            });

        },
        // 隐私协议点击
        privacyClick(item) {
            this.privacy = item.option[0].title;
            this.current = item;
            this.showPrivacy = true;
        },
        // 取随机数
        getRandom(min, max) {
            return min + Math.floor(Math.random() * (max - min + 1));
        },
        // 提交表单注册
        add_front_desk_registration_form() {
            if (!this.formSow) {
                this.selectChange();
                let arr = [];
                for (let item of this.form_info.form) {
                    if (item.require && item.type != "preview") {
                        if (item.select == 2) {
                            if (item.isMultipleChoice == 1 && item.value.toString() == '') {
                                this.$toast(`请输入${item.title}`);
                                return
                            } else if (item.isMultipleChoice == 2 && item.value.length == 0) {
                                this.$toast(`请输入${item.title}`);
                                return
                            }
                        } else {
                            if (item.value.toString() == '') {
                                this.$toast(`请输入${item.title}`);
                                return
                            }
                        }
                    }
                    if (item.type == "phone" && item.require) {
                        if (!/^[1][3-9]\d{9}$/.test(item.value)) {
                            this.$toast('请输入正确的手机号');
                            return
                        }
                        if (item.isPhoneValue && this.inputCode == '') {
                            this.$toast(`请输入验证码`);
                            return
                        }
                    }
                    if (item.type == "email" && item.require) {
                        if (!/^[A-Za-z0-9]+([-_.][A-Za-z0-9]+)*@([A-Za-z0-9]+[-.])+[A-Za-z0-9]{2,5}$/.test(item.value)) {
                            this.$toast('请输入正确的邮箱');
                            return
                        }
                    }
                    if (item.type == "preview" && item.require && !item.value) {
                        this.$toast(`请${item.title}`);
                        return
                    }
                    if (item.isUser_name) {
                        arr.push(item.value);
                    }
                }
                this.isSave = true;
                this.$store.dispatch("add_front_desk_registration_form", {
                    site_url: this.site_url,
                    nickname: arr.join('-'),
                    form: JSON.stringify(this.form_info.form),
                    code: this.inputCode
                }).then(res => {
                    this.isSave = false;
                    if (res.data.code == 200) {
                        this.$cookies.set("microtoken", res.data.data.token);
                        if (!this.$cookies.isKey('userId')) {
                            this.$cookies.set("userId", `bd${new Date().getTime()}`);
                        }
                        // 如果是微信登录则表单认证不存储wmUserInfo
                        if (!this.isWeixin) {
                            window.localStorage.wmUserInfo = JSON.stringify({ userId: this.$cookies.get('userId'), userTag: "表单用户", projectVersion: '1.0.1', env: 'dev' })
                        }
                        this.succeed = true
                        this.successText = "注册成功！"
                        this.$emit("isRegister");
                    }
                })
            } else {
                this.selectChange();
                let arr = [];
                for (let item of this.form_info.form) {
                    if (item.require && item.type != "preview") {
                        if (item.select == 2) {
                            if (item.isMultipleChoice == 1 && item.value.toString() == '') {
                                this.$toast(`请输入${item.title}`);
                                return
                            } else if (item.isMultipleChoice == 2 && item.value.length == 0) {
                                this.$toast(`请输入${item.title}`);
                                return
                            }
                        } else {
                            if (item.value.toString() == '') {
                                this.$toast(`请输入${item.title}`);
                                return
                            }
                        }
                    }
                    if (item.type == "phone" && item.require) {
                        if (!/^[1][3-9]\d{9}$/.test(item.value)) {
                            this.$toast('请输入正确的手机号');
                            return
                        }
                        if (item.isPhoneValue && this.inputCode == '') {
                            this.$toast(`请输入验证码`);
                            return
                        }
                    }
                    if (item.type == "email" && item.require) {
                        if (!/^[A-Za-z0-9]+([-_.][A-Za-z0-9]+)*@([A-Za-z0-9]+[-.])+[A-Za-z0-9]{2,5}$/.test(item.value)) {
                            this.$toast('请输入正确的邮箱');
                            return
                        }
                    }
                    if (item.type == "preview" && item.require && !item.value) {
                        this.$toast(`请${item.title}`);
                        return
                    }
                    if (item.isUser_name) {
                        arr.push(item.value);
                    }
                }
                this.isSave = true;
                this.$store.dispatch("upd_user_register_info", {
                    site_id: this.site_id,
                    nickname_field: arr.join('-'),
                    form: JSON.stringify(this.form_info.form),
                    id: this.formid,
                    code: this.inputCode,
                }).then(res => {
                    this.isSave = false;
                    if (res.data.code == 200) {
                        this.succeed = true
                        this.successText = "修改成功！"
                        this.$emit("isRegister");
                    }
                })
            }
        },
        // 表单查询
        form_details() {
            this.Loading = true;
            this.$store.dispatch("form_details", {
                site_url: this.site_url
            }).then(res => {
                this.Loading = false;
                if (res.data.code == 200) {
                    res.data.data ? this.form_info = res.data.data : '';
                    // 将隐私协议字段过滤出来
                    let arr = this.form_info.form.filter((item) => item.type == "preview");
                    this.form_info.form = this.form_info.form.filter((item) => item.type != "preview");
                    arr.forEach(item => this.form_info.form.push(item));
                    this.privacy_agreement_img_url = res.data.data.privacy_agreement_img_url
                    this.form_info.form.forEach(item => {
                        if (item.select == "2" && item.queRadio == "2") {
                            item.value = []
                        }
                        if (item.select2 == 10) {
                            item.value = []
                        }
                    })
                }
            })
        },
        //注册之后查询注册列表
        form_query() {
            this.$store.dispatch("form_query", {
                site_id: this.site_id
            }).then(res => {
                this.Loading = false;
                if (res.data.code == 200) {
                    this.formid = res.data.data.id
                    res.data.data ? this.form_info = res.data.data : '';
                    // 将隐私协议字段过滤出来
                    let arr = this.form_info.form.filter((item) => item.type == "preview");
                    this.form_info.form = this.form_info.form.filter((item) => item.type != "preview");
                    arr.forEach(item => this.form_info.form.push(item));
                    this.privacy_agreement_img_url = res.data.data.privacy_agreement_img_url
                    this.form_info.form.forEach(item => {
                        if (item.select == "2" && item.queRadio == "2") {
                            if (item.value.constructor !== Array) {
                                item.value = []
                            }
                        }
                        if (!item.value && item.select2 == 10) {
                            item.value = []
                        }
                    })
                }
            })
        },
        selectChange() {
            this.initialForm = this.form_info
            this.form_info.form.forEach(item => {
                item.select == 2 && item.value == "" ? this.selectNull = true : '';
            })
        },
        onSubmit(values) {
            this.add_front_desk_registration_form()
        },
        onConfirm(values) {
            this.showArea = false;
            this.current.value = values.filter((item) => !!item).map((item) => item.name).join('/');
        },
        tabsSwitch(index) {
            var _this = this;
            _this.tabsName.filter((item, j) => {
                item.isActive = false; //不是当前点击的不显示
                if (j == index) {
                    if (index == 0) {
                        this.Issing = true
                    } else {
                        this.Issing = false
                    }
                    item.isActive = true; //是当前点击的显示
                }
            });
        },
        // 点击获取验证码
        getCode() {
            this.form_info.form.forEach((item, index) => {
                if (item.type == "phone" && item.require) {
                    if (!/^[1][3-9]\d{9}$/.test(item.value)) {
                        this.$toast('请输入正确的手机号');
                        return
                    } else {
                        this.$store.dispatch('get_captcha', {
                            type: 6,
                            mobile: item.value,
                        }).then(res => {
                            if (res.data.code == 200) {
                                this.codeCountdown()
                            } else {
                                this.$toast(res.data.message);
                            }
                        })
                    }
                }
            })
        },
        //发送成功
        codeCountdown() {
            let that = this
            that.codeDisabled = true
            that.codeTime--
            that.codeText = `${that.codeTime}s 后获取`
            let codedate = setInterval(() => {
                that.codeTime--
                if (that.codeTime > 0) {
                    that.codeText = `${that.codeTime}s 后获取`
                }
                if (that.codeTime <= 0) {
                    clearInterval(codedate)
                    that.codeText = '获取验证码'
                    that.codeTime = 61
                    that.codeDisabled = false
                }
            }, 1000)
        },

    },
}
</script>
<style scoped lang="less">
@Color: var(--themecolor);

.flex {
    display: flex;
    align-items: center;
}

/deep/.van-empty,
/deep/.van-loading {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
}

/deep/.van-uploader__mask .van-loading {
    position: initial;
    transform: translateX(0%);
}

/deep/.van-empty {
    width: 100%;

    .van-empty__image {
        width: 300px;
        height: 300px;
    }
}

/deep/.van-loading {
    top: 50%;
}

.loginBox-page {
    height: 100%;
    width: 100%;
    overflow: hidden;
    overflow-y: scroll;
    max-width: 420px;
}

.loginBox {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    // overflow-y: scroll;
    background: #F7F8FA;
    position: relative;

    .login-main-box {
        width: 100%;
        min-height: 100%;
        overflow: hidden;
        overflow-y: scroll;
        position: relative;
    }

    .top {
        width: 100%;
        height: 2rem;
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
        }
    }

    .main {
        position: absolute;
        top: 1.8rem;
        width: 94%;
        left: 3%;
        background: #fff;
        box-shadow: 0px 3px 10px #EDEDED;
        border-radius: 6px;
        background: #fff;
        border-radius: 6px;
        padding: 0 .1rem;

        .successful {
            position: relative;
            text-align: center;
            padding: 15% 0;
            line-height: .2rem;

            .modify_register {
                position: absolute;
                top: 5%;
                right: 2%;
                font-size: 0.12rem;
                color: #8b8b8b;
            }

            img {
                width: 60%;
            }

            p {
                font-size: .18rem;
                color: #333;
                font-weight: bold;
                line-height: .6rem;
            }
        }

        /deep/.van-checkbox__label,
        /deep/.van-checkbox__icon {
            font-size: .12rem;
            margin-left: .06rem;
        }

        /deep/.van-cell {
            border-bottom: 1px solid #E5E5E5;
        }

        /deep/.cell-down {
            padding: 0 8px;

            .el-input,
            .el-input__inner,
            .el-select {
                width: 100%;
                border: 0;
                padding-left: 4px;
            }

            .van-cell {
                margin-bottom: 20px;
                height: 44px;
            }

            .van-cell__value {
                display: flex;
                align-items: center;
            }

        }

        .elInput {
            /deep/.el-input__inner::-webkit-input-placeholder {
                color: #ee0a24;
            }
        }

        /deep/.van-button--primary {
            color: #333;
            background-color: #fff;
            border: 1px solid #fff;
            font-weight: bold;
        }

        /deep/.van-field__control {
            padding-left: 10px;
        }

        /deep/.van-cell {
            margin-bottom: 10px;
        }

        /deep/.van-button--info {
            font-size: 14px;
            width: 1.3rem;
            height: .4rem;
            box-shadow: 0px 3px 15px #D6D6D6;
            background-color: @Color;
            border-color: #fff;
        }

        .regisbtn {
            position: absolute;
            left: 50%;
            transform: translateX(-54%);
            bottom: -35px;
            width: 1.5rem;
        }

        .submit-btn {
            // bottom: 9px;
            bottom: -30px;
            padding-bottom: 31px;
        }

        ul {
            width: 90%;
            margin: 0 auto;
            display: flex;

            li {
                width: 80px;
                margin-top: 0.12rem;
                text-align: center;
                color: #ffffff;
            }
        }

        ul .tab-link {
            display: block;
            text-align: center;
            color: #666;
            font-size: 16px;
            overflow: hidden;
            line-height: 38px;
        }

        ul .tab-img {
            display: none;
            width: 15px;
            margin: 0 auto;
        }

        ul .active {
            display: block;
            color: #000;
            font-weight: bold;
        }

        .tabsContent {
            border-radius: 6px;
            min-height: 3rem;

            .registerBox {
                width: 94%;
                background: #fff;
                margin: 0 auto;

                .registerCon {
                    padding-bottom: 100px;
                    padding-top: 10px;
                }
            }

            .proupBox {
                position: relative;
            }

            .proup {
                width: 140px;
                height: 90px;
                position: absolute;
                background: rgba(0, 0, 0, 0.7);
                box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
                border-radius: 3px;
                font-size: 16px;
                right: 0;
                top: 0;

                p {
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    color: #fff;
                }
            }
        }

        .dialog-title {
            background: #F7F8FA;
            padding: .15rem 0;
            text-align: center;
            font-size: .16rem;
            font-weight: bold;
        }

        .dialog-content {
            font-size: .14rem;
            min-height: 1.5rem;
            max-height: 3rem;
            overflow-y: auto;
            padding: .1rem .15rem;
            line-height: .25rem;
            word-break: break-all;
        }
    }

    .cover_img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background: #fff;
        overflow-x: hidden;
        overflow-y: auto;

        img {
            display: block;
            width: 100%;
        }

        .detail_btn {
            font-size: 13px;
            text-align: center;
            // background-color: rgb(230, 230, 230);
            // border: 1px solid #eee;
            background: rgba(0, 0, 0, 0.7);
            color: #fff;
            width: 45px;
            height: 45px;
            line-height: 45px;
            position: absolute;
            box-sizing: border-box;
            top: 0;
            right: 10px;
            // bottom: 0.15rem;
            margin: 10px auto;
            border-radius: 50%;
        }

        .isclick {
            background-color: #ccc;
        }

        .imgs {
            min-height: calc(100% - 0.5rem);
        }

        .checkboxs {
            font-size: .12rem;
            padding: 0 15px;
            margin: 10px 0;
            box-sizing: border-box;
        }
    }
}

.registerCon {
    /deep/.van-form {
        .van-cell {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 5px;
            padding: 13px 16px 5px;

            .van-cell__title {
                width: 100%;
                // padding-left: 5px;
                margin-bottom: 10px;
                margin-right: 0px;
                font-weight: bold;
            }

            .van-cell__value {
                width: 100%;

                .van-field__body {
                    .van-field__control {
                        color: #7d7d7e;
                        padding-left: 0px;
                    }
                }
            }
        }

        .van-radio-group {
            width: 100%;
            border: 1px solid #e1e1e1;
            border-radius: 3px;
            // margin-top: 14px;
            flex-direction: column;

            .van-radio {
                padding: 8px 8px;
                border-bottom: 1px solid #e0e0e0;

                &:nth-last-child(1) {
                    border-bottom: none;
                }

                span {
                    font-size: 14px;
                    color: #7d7d7e;
                }
            }
        }

        .van-checkbox-group {
            width: 100%;
            border: 1px solid #e1e1e1;
            border-radius: 3px;
            // margin-top: 14px;
            flex-direction: column;

            .van-checkbox {
                padding: 8px 8px;
                border-bottom: 1px solid #e0e0e0;

                &:nth-last-child(1) {
                    border-bottom: none;
                }

                .van-checkbox__icon {
                    margin-left: 0px;
                    border-radius: 2px;
                    overflow: hidden;
                }

                .van-checkbox__label {
                    font-size: 14px;
                    margin-left: 8px;
                    color: #7d7d7e;
                }
            }
        }

        .show-title {
            padding: 13px 8px 0px;
            border-bottom: none;

            .van-cell__value {
                flex-direction: column;
                width: 100%;
                align-items: flex-start;

                .title {
                    display: flex;

                    p {
                        padding-left: 5px;
                        color: #646566;
                        font-weight: bold;
                    }
                }

                .el-input__inner {
                    color: #7d7d7e;
                    padding-left: 0px;
                }

            }
        }
    }
}

/deep/.van-cell--required::before {
    top: 13px;
    left: 7px;
}

.iphone {
    padding: 0px !important;
    border-bottom: none !important;
}

.iphone-code {
    padding: 0px !important;
    position: relative;

    &::after {
        content: '';
        width: 65%;
        height: 1px;
        background: #e5e5e5;
        position: absolute;
        bottom: 0px;
        left: 0px;
    }

    /deep/.van-field {
        border-bottom: none !important;
    }

    /deep/.van-cell {
        padding: 10px 16px 5px !important;

        &::after {
            border-bottom: none !important;
        }

        // .van-field__body {
        //     .van-field__control {
        //         // width: 60%;
        //         // border-bottom: 1px solid #e5e5e5;
        //         // padding-bottom: 5px;
        //     }
        // }
    }

    .code {
        position: absolute;
        width: auto !important;
        height: auto !important;
        box-shadow: none !important;
        bottom: 5px;
        right: 0px;
        font-size: 13px !important;
        padding: 4px 22px;
        background-color: rgba(0, 0, 0, 0) !important;
        color: #000;

        &::before {
            background-color: rgba(0, 0, 0, 0) !important;
            border-color: rgba(0, 0, 0, 0) !important;
        }
    }
}
</style>
<style lang="less">
.el-select-dropdown__item {
    white-space: initial !important;
    max-width: 300px;
    height: auto !important;
    line-height: 24px !important;
    margin-top: 6px !important;
}
</style>