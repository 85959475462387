<template>
  <div class="footer">
    <div class="space"></div>
    <div class="dock">
      <div v-for="item in dockList" :key="item.pid" class="dock_box">
        <div class="item" @click="openApp(item)" :class="$store.state.nowApp.pid == item.pid ? 'jump' : ''"
          v-if="item && tool.isAppInKeepList(item, $store.state.dockAppList)">
          <div class="iconfont" :style="{
            background: item.module_style.module_background_color.selected == 1&&item.module_style.module_background_switch==1  ? `url(${item.module_style.module_background_color.module_background_url})` : '',
            backgroundColor: item.module_style.module_background_color.selected == 3 ? item.module_style.module_background_color.customize : '',
          }">
            <img :src="item.module_style.icon" alt="" />
          </div>
          <div class="dot" v-if="tool.isAppInOpenList(item, $store.state.openAppList)"></div>
          <div class="title" >{{ item.module_title_content.main_title_show_hidden?item.module_title_content.module_title:item.module_title }}</div>
          <div class="module_title" v-if="item.module_title_content.main_title_show_hidden">{{ item.module_title_content.module_title }}
        </div>
        </div>
      </div>
      <div class="item" @click="openApp(system)" v-if="$store.state.dockAppList.length!=0">
        <div class="iconfont iconfont_mode">
          <img src="@/assets/img/home/gengduo.svg" alt="">
        </div>
        <div class="title">{{ system.title }}</div>
      </div>
    </div>
    <div class="space"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      system: {
        key: "system_launchpad",
        component: "SystemLaunchPad",
        icon: "icon-shezhi",
        title: "启动台",
        // iconColor: "#333",
        // iconBgColor: "#d4dbef",
        width: 500,
        height: 300,
        hideInDesktop: true,
        keepInDock: true,
      },
    };
  },
  computed: {
    dockList() {
      if (this.$store.state.dockAppList.length > 10) {
        return this.$store.state.dockAppList.slice(0, 10)
      } else {
        return this.$store.state.dockAppList
      }

    }
  },
  methods: {
    openApp(item) {
      switch (item.key) {
        case "system_launchpad":
          this.$store.commit("launchpad");
          break;
        default:
          this.$store.commit("openApp", item);
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.dock {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(20px);
  border-radius: 10px;
  flex-direction: row;
  display: flex;
  padding: 2px;
  z-index: 99990;

  .dock_box {
    position: relative;
    margin: 0 2px;
    .module_title {
      font-size: 12px;
      position: absolute;
      bottom: 5px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      width: 100%;
      background: rgba(0, 0, 0, .7);
      border-radius: 5px;
    }
  }

  .item {
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom:8px ;
    .iconfont {

      background-size: cover !important;
      cursor: pointer;
      border-radius: 20px;
      padding: 2px;
      display: inline-block;
      border-radius: 10px;
      height: 50px;
      width: 50px;
      text-align: center;
      font-size: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: transform 0.3s, margin 0.3s;

      img {
        width: 20px;
        height: auto;
      }
    }
    .iconfont_mode{
      display: flex;
      img{
        width: 20px;
        height: 20px;
      }
    }
  }

  .item:hover {
    .iconfont {
      transform: scale(1.5) translateY(-10px);
      margin: 0px 15px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    }
    // .module_title{
    //   display: none;
    // }
    .title {
      position: absolute;
      display: inherit;
      word-break: keep-all;
      background: rgba(0, 0, 0, 0.3);
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 5px 10px;
      font-size: 12px;
      animation: dockTitleAnimation 0.5s ease 1 forwards;
    }
  }

  .dot {
    width: 3px;
    height: 3px;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    bottom: 0px;
    border-radius: 5px;
    display: inline-block;
    font-size: 0;
  }

  .title {
    display: none;
  }

  .jump {
    animation: jumpAnimation 0.8s ease 1;
  }
}
</style>
