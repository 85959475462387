<template>
    <div class="register_warp"  :style="{'--themecolors':themecolors}">
       <div class="register_warp_box" v-loading="loading">
           <div class="register_warp_left">
               <img :src="head_img" alt="">
           </div>
            <div class="register_warp_right">
               <div class="register_title">注册</div>
                <el-form ref="form" v-if="!loading&&form_info.form" >
                   <div v-for="(item,index) in form_info.form" :key="index">
                       <el-form-item  v-if="item.option.length == 0 && item.select2 != 3  && item.select2 != 4 && item.select2 != 10 ? item.select2 == 1 || item.select2 == 5 || item.select2 == 6 || item.select2 == 9 || item.select == '文本' || item.select == 1 
                                   ? true : false : false " :label="`${(index+1)+'. '+item.title}`" :rules="[{ required: item.require}]">
                               <el-input placeholder="请输入内容"  v-model="item.value"></el-input>
                       </el-form-item>
                       <el-form-item v-if="item.select2 == 2 || (item.select == 2 && item.queRadio == 1 && item.select2 !=8)" :label="`${(index+1)+'. '+item.title}`" :rules="[{ required: item.require}]">
                               <el-radio-group v-model="item.value" >
                                       <el-radio v-for="(j,k) in item.option" :key="k" :label="j.title" ></el-radio>
                               </el-radio-group>
                       </el-form-item>
                           <el-form-item v-if="(item.select2 != 2 && item.select2 !=8) && (item.select == 2 && item.queRadio == 2)" :label="`${(index+1)+'. '+item.title}`" :rules="[{ required: item.require}]">
                               <el-checkbox-group v-model="item.value">
                                   <el-checkbox v-for="(j,k) in item.option" :key="k" :label="j.title" name="type"></el-checkbox>
                               </el-checkbox-group>
                       </el-form-item>
                       <el-form-item  v-if="item.select2 == 10" :label="`${(index+1)+'. '+item.title}`" :rules="[{ required: item.require}]">
                               <van-uploader v-model="item.value" :after-read="afterRead" :preview-full-image="false" :max-count="1" :max-size="10 * 1024 * 1024" @oversize="onOversize" accept=".jpeg,.jpg,.png,.gif"/>
                       </el-form-item>
                       <el-form-item  v-if="item.select2 == 4" :label="`${(index+1)+'. '+item.title}`" :rules="[{ required: item.require}]">
                               <el-input placeholder="请输入手机号" v-model="item.value"></el-input>
                               <el-input  class="code" placeholder="请输入验证码" maxlength="6"  @input="inputCode= inputCode.replace(/[^\d.]/g,'')" v-model="inputCode" v-if="item.select2 == 4 && item.isPhoneValue">
                                   <el-button slot="append"  :disabled='codeDisabled' @click="getCode">{{codeText}}</el-button>
                               </el-input>
                       </el-form-item>
                           <el-form-item  v-if="item.select2 == 3" :label="`${(index+1)+'. '+item.title}`" :rules="[{ required: item.require}]">
                               <!-- ChangeArea = item,showArea = true -->
                               <div  @click="ChangeArea = item">  
                                   <el-cascader ref="cascader" v-model="ruleForm.area" placeholder="请选择地址" :options="options" @change="handleChange"></el-cascader>
                               </div>
                       </el-form-item>
                       
                       <div @click="privacyClick(item)" v-if="item.select2 == 8" class="form_privacy">
                           <el-checkbox  v-model="item.value" disabled>{{item.title}}</el-checkbox>
                       </div>
                   </div>
                   <el-form-item size="large" class="form_btn">
                       <el-button round  @click="add_front_desk_registration_form" :color="themecolors">提交</el-button>
                   </el-form-item>
               </el-form>
               <div v-if="!loading&&form_info.form==0">
                   <el-empty :image="require('@/assets/attend/none-img.svg')" :image-size="400" description="暂无注册内容"></el-empty>
               </div>
               <el-dialog :visible.sync="showPrivacy" top="25vh">
                       <!-- <div class="dialog-title">隐私协议</div> -->
                       <div class="dialog-content" v-html="privacy"></div>
                       <span slot="footer" class="dialog-footer">
                           <el-button @click="showPrivacy = false">取 消</el-button>
                           <el-button type="primary" @click="current.value = true,showPrivacy = false">确 定</el-button>
                       </span>
               </el-dialog>
            </div>
       </div>
 </div>
</template>

<script>
import { mapState } from "vuex";
import provAndCity from "@/js/area.json";
export default {
  props:{
    site_id:String
  },
 computed: {
   ...mapState(['themecolors']),
 },
 data() {
   return {
     siteUrl: "",
     loading: true,
     form_info: [], //注册表单数据
     head_img:"",//kv图片
     privacy_agreement_img_url: "", //注册表单导航图
     qiniutoken: "", //七牛token
     codeDisabled: false, //短信验证码倒计时
     codeText: "获取验证码",
     codeTime: 61,
     isSave: false,
     inputCode: "",
     privacy: "", // 隐私协议
     showPrivacy: false, // 隐私协议弹窗
     options: [],
     current: {},
     ChangeArea: "",
     ruleForm: {},
   };
 },
 created() {
   // 判断是从跳转还是在当前页面加载
   this.siteUrl = this.$route.params.site_url ? this.$route.params.site_url
     : sessionStorage.getItem("siteUrl");
   this.GetToken();
   this.form_details();
 },
 mounted() {
   this.options = provAndCity;
 },
 methods: {
   handleChange(e) {
     if (e && e.length > 0) {
       this.ruleForm.province =
         this.$refs["cascader"][0].getCheckedNodes()[0].pathLabels[0];
       this.ruleForm.city =
         this.$refs["cascader"][0].getCheckedNodes()[0].pathLabels[1];
       this.ruleForm.label =
         this.$refs["cascader"][0].getCheckedNodes()[0].pathLabels;
     } else {
       this.ruleForm.province = "";
       this.ruleForm.city = "";
     }
     this.ChangeArea.value =
       this.ruleForm.label[0] +
       "/" +
       this.ruleForm.label[1] +
       "/" +
       this.ruleForm.label[2];
   },
   // 七牛token
   GetToken() {
     this.$store
       .dispatch("get_verify_space_size", {
         site_id: this.site_id,
         storage_space: "1",
         size: "86094",
       })
       .then((res) => {
         if ((res.data.code = 200)) {
           this.qiniutoken = res.data.data.upload_token;
         }
       });
   },
   onOversize(file) {
     this.$toast("图片大小不能超过10MB");
   },
   // 图片上传到服务器
   afterRead(file, detail) {
     this.isSave = true;
     file.status = "uploading";
     let uptoken = this.qiniutoken;
     let key = new Date().getTime() + Math.random(1000) + file.file.name; //这是上船后返回的文件名，这里为了避免重复，加上了时间戳和随机数
     var config = {
       useCdnDomain: true, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
       domain: "http://images.qingtv.com", //配置好的七牛云域名  如   https://cdn.qniyun.com/
       chunkSize: 100, //每个分片的大小，单位mb，默认值3
       forceDirect: true, //直传还是断点续传方式，true为直传
     };
     var putExtra = {
       fname: file.file.name, //文件原始文件名
       params: {},
       mimeType: [] || null,
       // ...config,
     };
     var observable = this.$qiniu.upload(
       file.file,
       key,
       uptoken,
       putExtra,
       config
     );
     observable.subscribe({
       next: (result) => {
         file.message = `上传中${Math.trunc(result.total.percent)}%`;
       },
       error: (err) => {
         //上传错误后触发
         console.log(err);
         // reject(err)
       },
       complete: (result) => {
         //上传成功后触发。包含文件地址。
         this.isSave = false;
         file.status = "";
         file.message = "";
         this.form_info.form.forEach((item) => {
           if (item.select2 == 10) {
             item.value[detail.index] = {
               url: "https://images.qingtv.com/" + result.key + "",
             };
           }
         });
         resolve(result);
       },
     });
   },
   // 表单查询
   form_details() {
     this.$store
       .dispatch("form_details", {
         site_url: this.siteUrl,
       })
       .then((res) => {
         if (res.data.code == 200) {
           res.data.data ? (this.form_info = res.data.data) : "";
           // 将隐私协议字段过滤出来
           let arr = this.form_info.form.filter(
             (item) => item.type == "preview"
           );
           this.form_info.form = this.form_info.form.filter(
             (item) => item.type != "preview"
           );
           arr.forEach((item) => this.form_info.form.push(item));
           this.privacy_agreement_img_url =res.data.data.privacy_agreement_img_url;
           this.head_img=res.data.data.head_img
           this.form_info.form.forEach((item) => {
             if (item.select == "2" && item.queRadio == "2") {
               item.value = [];
             }
             if (item.select2 == 10) {
               item.value = [];
             }
           });
           this.loading = false;
         }
       });
   },
   //发送成功
   codeCountdown() {
     let that = this;
     that.codeDisabled = true;
     that.codeTime--;
     that.codeText = `${that.codeTime}s 后获取`;
     let codedate = setInterval(() => {
       that.codeTime--;
       if (that.codeTime > 0) {
         that.codeText = `${that.codeTime}s 后获取`;
       }
       if (that.codeTime <= 0) {
         clearInterval(codedate);
         that.codeText = "获取验证码";
         that.codeTime = 61;
         that.codeDisabled = false;
       }
     }, 1000);
   },
   // 隐私协议点击
   privacyClick(item) {
     this.privacy = item.option[0].title;
     this.current = item;
     this.showPrivacy = true;
   },
   // 点击获取验证码
   getCode() {
     this.form_info.form.forEach((item, index) => {
       if (item.type == "phone" && item.require) {
         if (!/^[1][3-9]\d{9}$/.test(item.value)) {
           this.$toast("请输入正确的手机号");
           return;
         } else {
           this.$store
             .dispatch("get_captcha", {
               type: 6,
               mobile: item.value,
             })
             .then((res) => {
               if (res.data.code == 200) {
                 this.codeCountdown();
               } else {
                 this.$toast(res.data.message);
               }
             });
         }
       }
     });
   },
   // 提交表单注册
   add_front_desk_registration_form() {
     let arr = [];
     for (let item of this.form_info.form) {
       if (item.require && item.type != "preview") {
         if (item.select == 2) {
           if (item.isMultipleChoice == 1 && item.value.toString() == "") {
             this.$toast(`请输入${item.title}`);
             return;
           } else if (item.isMultipleChoice == 2 && item.value.length == 0) {
             this.$toast(`请输入${item.title}`);
             return;
           }
         } else {
           if (item.value.toString() == "") {
             this.$toast(`请输入${item.title}`);
             return;
           }
         }
       }
       if (item.type == "phone" && item.require) {
         if (!/^[1][3-9]\d{9}$/.test(item.value)) {
           this.$toast("请输入正确的手机号");
           return;
         }
         if (item.isPhoneValue && this.inputCode == "") {
           this.$toast(`请输入验证码`);
           return;
         }
       }
       if (item.type == "email" && item.require) {
         if (
           !/^[A-Za-z0-9]+([-_.][A-Za-z0-9]+)*@([A-Za-z0-9]+[-.])+[A-Za-z0-9]{2,5}$/.test(
             item.value
           )
         ) {
           this.$toast("请输入正确的邮箱");
           return;
         }
       }
       if (item.type == "preview" && item.require && !item.value) {
         this.$toast(`请${item.title}`);
         return;
       }
       if (item.isUser_name) {
         arr.push(item.value);
       }
     }
     this.isSave = true;
     this.$store
       .dispatch("add_front_desk_registration_form", {
         site_url: this.siteUrl,
         nickname: arr.join("-"),
         form: JSON.stringify(this.form_info.form),
         code: this.inputCode,
       })
       .then((res) => {
         this.$toast(res.data.message);
         this.isSave = false;
         if (res.data.code == 200) {
           this.$cookies.set("microtoken", res.data.data.token);
           if (!this.$cookies.isKey("userId")) {
             this.$cookies.set("userId", `bd${new Date().getTime()}`);
           }
          // 执行判断是否需要全局注册
           this.$emit("register");
           //路由跳转注册成功回到首页
           if (this.$route.query.singePage == "singePage") {
             this.$router.push({ path: `event/${this.siteUrl}` });
           } else {
             this.$parent.registrationShow = false;
           }
           // 如果是微信登录则表单认证不存储wmUserInfo
           if (localStorage.getItem("wmUserInfo")||JSON.parse(localStorage.getItem("wmUserInfo")).userTag!=="微信用户") {
            localStorage.setItem("wmUserInfo", JSON.stringify({ userId:this.$cookies.get("userId"), userTag: "表单用户", projectVersion: "1.0.1", env: "dev", }));
           }
         }
       });
   },
 },
};
</script>

<style lang="less" scoped>
@Color:var(--themecolors);
.register_warp {
 width: 100%;
 height: 100vh;
 position: relative;
 font-size: 15px;
 display: flex;
 align-items: center;
 backdrop-filter: blur(100px);
 /deep/.el-empty__description {
   p {
     font-size: 16px;
   }
 }
 .register_warp_box {
  background: #fff;
   border-radius: 10px;
   width: 1200px;
   height: 505px;
   margin: 0 auto;
   display: flex;
   overflow: hidden;
    box-shadow: 0px 3px 10px 1px #9e9e9e;
    /deep/.el-loading-mask{
       background-color:rgba(255,255,255,1);
       transition:none;
    }
   .register_warp_left {
     width: 784px;
     flex: 1;
     img{
       width: 100%;
       height: 100%;
       object-fit: cover;
     }
   }
   .register_warp_right {
     position: relative;
     width: 416px;
     min-width: 400px;
     padding: 24px;
     height: 505px;
     .register_title{
       width: 100%;
       text-overflow: ellipsis;
       white-space: nowrap;
       overflow: hidden;
       font-size: 18px;
       font-weight: bold;
       word-break: break-word;
       color: #2f3136;
       padding-bottom: 20px;
       border-bottom: 1px solid #dcdfe6;
     }
   }
 }
 .el-cascader {
   width: 100%;
 }
 /deep/.el-form {
   max-height: 365px;
   overflow:auto;
     padding-right: 5px;
     margin-top: 5px;
   &::-webkit-scrollbar{
       width:4px;
   }   
   &::-webkit-scrollbar-thumb{
       background:#dcdfe6;
       border-radius: 2px;
   }
   .el-form-item__label {
     font-weight: bold;
   }
   .el-form-item__content {
     width: 100%;
   }
   .el-form-item {
     display: flex;
     flex-direction: column;
     align-items: flex-start;
     margin-bottom: 10px;
   }
   .el-input__inner {
     height: 35px;
     line-height: 35px;
   }
   .el-input.is-active .el-input__inner, .el-input__inner:focus{
       border-color:@Color;
   }
   .code {
     margin-top: 10px;
   }
   .el-input-group__append {
     font-size: 13px;
     padding: 4px 22px;
     color: #000;
     border: none;
     border-bottom: 1px solid #dcdfe6;
     border-radius: 0;
     background: #fff;
     &::before {
       background-color: rgba(0, 0, 0, 0) !important;
       border-color: rgba(0, 0, 0, 0) !important;
     }
   }
   .el-checkbox__input.is-checked .el-checkbox__inner,
   .el-checkbox__input.is-indeterminate .el-checkbox__inner,
   .el-radio__input.is-checked .el-radio__inner {
     background-color: @Color;
     border-color: @Color;
   }
   .el-checkbox__input.is-checked + .el-checkbox__label,
   .el-radio__input.is-checked + .el-radio__label {
     color: @Color;
   }
   .el-checkbox__input.is-disabled + span.el-checkbox__label {
     color: #323233;
   }
   .el-button {
     background-color: @Color;
     border-color: @Color;
     color: #fff;
     padding: 10px 30px;
     border-radius: 0;
     font-size: 15px;
     width: 95%;
     border-radius: 5px;
   }
   .form_btn {
       width:calc(100% - 48px);
     position: absolute;
     bottom: 10px;
     left: 24px;
     border-top: 1px solid #dcdfe6;
     padding-top: 12px;
     text-align: center;
     margin-bottom: 0;
   }
   .form_privacy {
     margin-bottom: 20px;
     .el-checkbox__input.is-disabled + span.el-checkbox__label,
     .el-checkbox__input.is-disabled .el-checkbox__inner,
     .el-checkbox__input.is-disabled .el-checkbox__inner::after {
       cursor: pointer;
     }
     .el-checkbox__input.is-disabled .el-checkbox__inner {
       background-color: #fff;
     }
     .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
       border-color: @Color;
     }
   }
 }
 /deep/.el-dialog {
   width: 450px;
   max-height: 400px;
   border-radius: 5px;
   .el-dialog__body {
     max-height: 300px;
   }
   .dialog-content {
     height: 100%;
     overflow-y: scroll;
   }
   .el-button--primary {
     background-color: @Color;
     border-color: @Color;
   }
   .el-button:focus,
   .el-button:hover {
     color: #606266;
     border-color: #dcdfe6;
     background-color: #fff;
   }
 }
}
</style>